import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { CurrencyPipe, DecimalPipe, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr, 'fr-FR');

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

import { SharedModule } from '~/app/shared/shared.module';
import { StartupService } from '~/app/shared/services/startup.service';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CustomTranslateLoader } from './shared/services/translate.loader';

import { SetupComponent } from './setup/setup.component';
import { HomeComponent } from './home/home.component';
import { WinproComponent } from './winpro/winpro.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { ComponentsLibraryComponent } from './components-library/components-library.component';

// AoT requires an exported function for factories
export function loadConfig(start:StartupService) {
  return () => start.startup().then().catch((e:Error) => {});
}

@NgModule({
  declarations: [
    AppComponent,
    SetupComponent,
    HomeComponent,
    WinproComponent,
    ConfigurationComponent,
    ComponentsLibraryComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [ HttpClient ]
      }
    }),
    SharedModule.forRoot(),
    AppRoutingModule
  ],
  providers: [
    StartupService,
    CurrencyPipe,
    DecimalPipe,
    {
      provide: LOCALE_ID,
      useValue: 'fr-FR'
    },
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfig,
      deps: [StartupService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
