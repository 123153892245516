import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LocalizeRouterService } from 'localize-router';

import { environment } from '~/environments/environment';
import { ContextTypes } from '~/environments/environment.types';

import { CameleonService, CameleonStates } from '~/app/shared/cameleon/cameleon.service';

@Injectable()
export class WinproGuard implements CanActivate {
  constructor(protected cameleonService: CameleonService, protected router: Router, private localize: LocalizeRouterService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):boolean {
    if (this.cameleonService.completed) {
      return true;
    } else {
      this.router.navigate([this.localize.translateRoute('/')]);
      return false;
    }

  }
}
