export class BV {

  _id:string;
  _cpe:string;
  _description:string;
  _infos:string;
  _image:string;
  _type:string = 'object';
  _rawData:any;
  _authorized:boolean;

  constructor(data:any = null, type:any = null) {
    if (type == 'com.cameleon.framework.business.propertyvalue.IntegerValue') {
      this._type = 'integer';
    }

    if (type == 'com.cameleon.framework.business.propertyvalue.BooleanValue') {
      this._type = 'boolean';
    }

    if (type == 'com.cameleon.framework.business.propertyvalue.TextValue') {
      this._type = 'text';
    }

    if (data) {
      this.update(data);
    }
  }

  public update(data:any) {
    if (data['$']) {
      let tid = data['$'];

      let customBV = data['$'].match(/\/descr\=(.*)/)
      if (customBV) {
        tid = data['$'].replace(/\/descr\=(.*)/g, '');
        this._description = customBV[1];
        //this._type = 'string';
      }

      this._id = tid;
      if (this._type == 'boolean') {
        this._cpe = tid;
        this._description = tid == "true" ? 'Oui' : 'Non';
      } else if (this._type == 'text') {
        this._cpe = tid;
        this._description = tid;
      } else {
        this._cpe = 'CPE.'+tid;
      }
      
      this._authorized = data['@status'] == "AUTHORIZED";
    }

    if (data['@cpe']) {
      this._id = data['@cpe'].replace('CPE.', '');
      this._cpe = data['@cpe'];
      this._description = data.Description;
    }

    if (data['RMO'] && data['RMO']['RMOImage'] && data['RMO']['RMOImage'][0]) {
      this._image = data['RMO']['RMOImage'][0]['@onImage'];
    }

    if (data['RMO'] && data['RMO']['RMOText'] && data['RMO']['RMOText'][0]) {
      this._infos = data['RMO']['RMOText'][0]['Text'].replace(/(\r\n|\n|\r)/g, '<br/>');
    }

    this._rawData = data;
  }

  get id():string {
    return this._id;
  }

  get cpe():string {
    return this._cpe;
  }

  get description():string {
    return this._description;
  }

  get infos():string {
    return this._infos;
  }

  get image():string {
    return this._image;
  }

  get type():string {
    return this._type;
  }

  get authorized():boolean {
    return this._authorized;
  }
}
