import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, HostListener } from '@angular/core';

import { CameleonService } from '~/app/shared/cameleon/cameleon.service';

@Component({
  selector: 'simple-select',
  templateUrl: './simple-select.component.html',
  host: {
    class: 'select'
  }
})
export class SimpleSelectComponent implements OnInit, OnDestroy {

  private _json:any = {
    "parameters": [
      {
        "@name": "valuations",
        "list": [
          {
            "pair": {
              "left": {
                "cpe": ""
              },
              "right": {
                "SingleValuation": {
                  "@isCommentUserChoice": "false",
                  "@isQtyUserChoice": "false",
                  "@isValueUserChoice": "true",
                  "Value": {
                    "@userQty": null,
                    "pkValue": ""
                  },
                  "Comment": null
                }
              }
            }
          }
        ]
      }
    ]
  };

  /**
   * Data
   */
  private _fp:any;
  @Input()
  set fp(data: any) {
    this._fp = data;
    this._json.parameters[0].list[0].pair.left.cpe = this.fp.id;

    this._value = this.fp.value;
    this._json.parameters[0].list[0].pair.right.SingleValuation.Value.pkValue = this._value;
  }
  get fp(): any { return this._fp; }


  _value:string = '';
  set value(data:string) {
    this._value = data;
    this._json.parameters[0].list[0].pair.right.SingleValuation.Value.pkValue = data;
    this.onChange.emit(this._json);
  }
  get value(): string { return this._value; }

  @Output() onChange = new EventEmitter<any>();

  /**
	 * Constructor
	 */
  constructor(
    public cameleonService:CameleonService,
  ) {

  }

	/**
	 * On init
	 */
	ngOnInit() {

	}

	/**
	* On destroy
	*/
	ngOnDestroy() {

	}
}
