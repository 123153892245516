import { NgModule, ModuleWithProviders } from '@angular/core';
import { FormsModule }   from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { SafeUrlPipe, FilterPipe, Nl2BrPipe } from './shared.pipes';

import { ModalService } from './modal/modal.service';
import { ModalComponent } from './modal/modal.component';

import { LoaderComponent } from './loader/loader.component';
import { SimpleSelectComponent } from './components/simple-select/simple-select.component';
import { TextInputComponent } from './components/textinput/textinput.component';
import { ImageSelectGroupComponent } from './components/image-select-group/image-select-group.component';
import { CheckboxGroupComponent } from './components/checkbox-group/checkbox-group.component';
import { RadioGroupComponent } from './components/radio-group/radio-group.component';

import { AnalyticsService } from './services/analytics.service';
import { ToolsService } from './services/tools.service';
import { CameleonService } from './cameleon/cameleon.service';
import { ApplicationData } from './data/application.data';
import { ApplicationState } from './data/application.state';
import { WinproGuard } from '../app-routing.guards';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule.forChild({})
  ],
  declarations: [
    FilterPipe,
    LoaderComponent,
    SimpleSelectComponent,
    TextInputComponent,
    ImageSelectGroupComponent,
    CheckboxGroupComponent,
    RadioGroupComponent,
    ModalComponent,
    SafeUrlPipe,
    Nl2BrPipe,
  ],
  exports: [
    CommonModule,
    FormsModule,
    FilterPipe,
    LoaderComponent,
    SimpleSelectComponent,
    TextInputComponent,
    ImageSelectGroupComponent,
    CheckboxGroupComponent,
    RadioGroupComponent,
    ModalComponent,
    SafeUrlPipe,
    Nl2BrPipe,
    TranslateModule,
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        AnalyticsService,
        ToolsService,
        CameleonService,
        ApplicationData,
        ApplicationState,
        ModalService,
        WinproGuard,
      ]
    };
  }
}
