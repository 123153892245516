export var ApplicationStates;
(function (ApplicationStates) {
    ApplicationStates[ApplicationStates["LOADING"] = 1] = "LOADING";
    ApplicationStates[ApplicationStates["READY"] = 2] = "READY";
    ApplicationStates[ApplicationStates["ERROR"] = 3] = "ERROR";
})(ApplicationStates || (ApplicationStates = {}));
var ApplicationState = /** @class */ (function () {
    function ApplicationState() {
        this.state = null;
        this._loading = 0;
        this.loadingLabel = '';
    }
    Object.defineProperty(ApplicationState.prototype, "loading", {
        get: function () {
            return this._loading > 0;
        },
        /**
         * loading
         */
        set: function (value) {
            this._loading = Math.max(0, value ? this._loading + 1 : this._loading - 1);
            if (!this.loading) {
                this.loadingLabel = '';
            }
        },
        enumerable: true,
        configurable: true
    });
    return ApplicationState;
}());
export { ApplicationState };
