import { Component, Output, EventEmitter, ViewChild } from '@angular/core';

import { CameleonService } from '~/app/shared/cameleon/cameleon.service';
import { ApplicationData } from '~/app/shared/data/application.data';

@Component({
  moduleId: module.id,
  selector: 'app-home',
  templateUrl: 'home.component.html',
  styleUrls: ['home.component.scss'],
  host: {
    'class': ''
  },
})

export class HomeComponent {

  /**
   * Constructor
   */
  constructor(
    public cameleonService:CameleonService,
    public applicationData:ApplicationData,
  ) {

  }

  /**
   * On init
   */
  ngOnInit() {

  }
}
