import { EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { CameleonService } from '~/app/shared/cameleon/cameleon.service';
var TextInputComponent = /** @class */ (function () {
    /**
       * Constructor
       */
    function TextInputComponent(cameleonService) {
        this.cameleonService = cameleonService;
        this._json = {
            "parameters": [
                {
                    "@name": "valuations",
                    "list": [
                        {
                            "pair": {
                                "left": {
                                    "cpe": ""
                                },
                                "right": {
                                    "SingleValuation": {
                                        "@isCommentUserChoice": "false",
                                        "@isQtyUserChoice": "false",
                                        "@isValueUserChoice": "true",
                                        "Value": {
                                            "@userQty": null,
                                            "integerValue": ""
                                        },
                                        "Comment": null
                                    }
                                }
                            }
                        }
                    ]
                }
            ]
        };
        this.onChange = new EventEmitter();
    }
    Object.defineProperty(TextInputComponent.prototype, "fp", {
        get: function () { return this._fp; },
        set: function (data) {
            this._fp = data;
            this._json.parameters[0].list[0].pair.left.cpe = this.fp.id;
            this._value = this.fp.value;
            this._oldvalue = this.fp.value;
            this._json.parameters[0].list[0].pair.right.SingleValuation.Value.integerValue = this._value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TextInputComponent.prototype, "value", {
        get: function () { return this._value; },
        set: function (data) {
            this._value = data;
            this._json.parameters[0].list[0].pair.right.SingleValuation.Value.integerValue = data;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * On init
     */
    TextInputComponent.prototype.ngOnInit = function () {
    };
    /**
       * On blur
       */
    TextInputComponent.prototype.onBlur = function () {
        if (this.value !== null && this._oldvalue !== this.value) {
            this._oldvalue = this.value;
            this.onChange.emit(this._json);
        }
        if (this.value === null) {
            this.value = this._oldvalue;
        }
    };
    /**
       * On key down
       */
    TextInputComponent.prototype.onKeydown = function (event) {
        if (event.key === "Enter" && this.value !== null && this._oldvalue !== this.value) {
            this._oldvalue = this.value;
            this.onChange.emit(this._json);
        }
    };
    /**
    * On destroy
    */
    TextInputComponent.prototype.ngOnDestroy = function () {
    };
    return TextInputComponent;
}());
export { TextInputComponent };
