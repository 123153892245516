/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "@angular/common";
import * as i3 from "./radio-group.component";
import * as i4 from "../../cameleon/cameleon.service";
var styles_RadioGroupComponent = [];
var RenderType_RadioGroupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RadioGroupComponent, data: {} });
export { RenderType_RadioGroupComponent as RenderType_RadioGroupComponent };
function View_RadioGroupComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "radio-group__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 9, "div", [["class", "radio"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 6, "input", [["type", "radio"]], [[8, "id", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 3).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 3)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 3)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("change" === en)) {
        var pd_4 = (i0.ɵnov(_v, 4).onChange() !== false);
        ad = (pd_4 && ad);
    } if (("blur" === en)) {
        var pd_5 = (i0.ɵnov(_v, 4).onTouched() !== false);
        ad = (pd_5 && ad);
    } if (("ngModelChange" === en)) {
        var pd_6 = ((_co.value = $event) !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 16384, null, 0, i1.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i1.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵdid(4, 212992, null, 0, i1.RadioControlValueAccessor, [i0.Renderer2, i0.ElementRef, i1.ɵangular_packages_forms_forms_j, i0.Injector], { name: [0, "name"], value: [1, "value"] }, null), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i1.DefaultValueAccessor, i1.RadioControlValueAccessor]), i0.ɵdid(6, 671744, null, 0, i1.NgModel, [[8, null], [8, null], [8, null], [6, i1.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i1.NgControl, null, [i1.NgModel]), i0.ɵdid(8, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null), (_l()(), i0.ɵeld(9, 0, null, null, 1, "label", [["class", "radio__label"]], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i0.ɵted(10, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.fp.id; var currVal_9 = _v.context.$implicit.id; _ck(_v, 4, 0, currVal_8, currVal_9); var currVal_10 = _co.fp.id; var currVal_11 = _co.value; _ck(_v, 6, 0, currVal_10, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.fp.id + "/") + _v.context.$implicit.id); var currVal_1 = i0.ɵnov(_v, 8).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 8).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 8).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 8).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 8).ngClassValid; var currVal_6 = i0.ɵnov(_v, 8).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 8).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_12 = ((_co.fp.id + "/") + _v.context.$implicit.id); _ck(_v, 9, 0, currVal_12); var currVal_13 = _co.cameleonService.getBVDescription(_v.context.$implicit); _ck(_v, 10, 0, currVal_13); }); }
export function View_RadioGroupComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_RadioGroupComponent_1)), i0.ɵdid(1, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fp.bvs; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_RadioGroupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "radio-group", [["class", "radio-group"]], null, null, null, View_RadioGroupComponent_0, RenderType_RadioGroupComponent)), i0.ɵdid(1, 245760, null, 0, i3.RadioGroupComponent, [i4.CameleonService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RadioGroupComponentNgFactory = i0.ɵccf("radio-group", i3.RadioGroupComponent, View_RadioGroupComponent_Host_0, { fp: "fp" }, { onChange: "onChange" }, []);
export { RadioGroupComponentNgFactory as RadioGroupComponentNgFactory };
