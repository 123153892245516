import { BrandTypes, ContextTypes } from './environment.types';

export const environment = {
  PRODUCTION: false,
  BRAND: BrandTypes.BOUVET,
  CONTEXT: ContextTypes.WEB,
  // EXTRANET_SERVER: 'https://betaconfigws.menuiseries-bouvet.com',
  EXTRANET_SERVER: 'https://preprod-configurateur.menuiseries-bouvet.com',
  SERVER: 'https://betaconfigurateur.menuiseries-bouvet.com/',
  EXTRANET_API: '/api',
  WS_SERVER: 'https://web12.bouvet-sa.fr',
  WS_API: '/api',
  WS_USER: 'EXT_PASSERELLE_REC',
  WS_PWD: 'Zu59WafGghnLHJ99UHTA',
  CAMELEON_SERVER: 'https://web03.bouvet-sa.fr',
  CAMELEON_API: '/cameleonRest/api/configurators',
  CAMELEON_USER: 'cameleon',
  CAMELEON_PWD: 'leon',
  KEEP_ALIVE: (2 * 3600),
  GOOGLE_ANALYTICS_ID: '',
  VERSION: '1.4.0'
};
