import { ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalizeRouterService } from 'localize-router';
import { CameleonService, CameleonStates } from '~/app/shared/cameleon/cameleon.service';
import { FPTypes } from '~/app/shared/cameleon/vo/FP.vo';
import { ApplicationState } from '~/app/shared/data/application.state';
import { ApplicationData } from '~/app/shared/data/application.data';
import { ModalService } from '~/app/shared/modal/modal.service';
var ConfigurationComponent = /** @class */ (function () {
    /**
     * Constructor
     */
    function ConfigurationComponent(cameleonService, applicationState, applicationData, modalService, route, router, localize) {
        this.cameleonService = cameleonService;
        this.applicationState = applicationState;
        this.applicationData = applicationData;
        this.modalService = modalService;
        this.route = route;
        this.router = router;
        this.localize = localize;
        this.displayMessage = false;
        this.messages = [];
        this.FPTypes = FPTypes;
        this.FO = "";
    }
    ConfigurationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.sub = this.route.params.subscribe(function (params) {
            _this.cameleonService.CP = params['cp'];
            _this.FO = params['fo'];
            _this.applicationData.reference = params['ref'];
            if (!_this.applicationData.reference || !_this.cameleonService.CP || _this.applicationData.reference == 'null' || _this.cameleonService.CP == 'null') {
                _this.router.navigate([_this.localize.translateRoute('/config')]);
            }
            else {
                setTimeout(function () {
                    _this.applicationState.loading = true;
                    if (_this.cameleonService.state != CameleonStates.READY) {
                        if (_this.applicationData.base64Data) {
                            _this.applicationData.autoNext = false;
                            _this.initCameleon(_this.applicationData.base64Data);
                        }
                        else if (_this.applicationData.itemId) {
                            _this.cameleonService.getDataFromExtranet(_this.applicationData.itemId)
                                .then(function (result) {
                                _this.applicationData.autoNext = false;
                                if (result.base64) {
                                    _this.cameleonService.version = parseInt(result.version);
                                    _this.initCameleon(result.base64);
                                }
                                else {
                                    _this.cameleonService.version = null;
                                    _this.initCameleon(result);
                                }
                            }, function (error) {
                                _this.applicationState.loading = false;
                                _this.modalService.open('modal-fatal-error', { title: 'UNE ERREUR EST SURVENUE', text: error.message.$ });
                            }).catch(function (e) {
                                console.log(e);
                                _this.modalService.open('modal-fatal-error', { title: 'UNE ERREUR EST SURVENUE', text: 'Une erreur inconnue est survenue. Merci de fermer cette fenêtre et de recommencer une configuration.' });
                                _this.applicationState.loading = false;
                            });
                        }
                        else {
                            _this.initCameleon();
                        }
                    }
                    else {
                        _this.init();
                    }
                }, 100);
            }
        });
    };
    /**
     * Init caméléon
     */
    ConfigurationComponent.prototype.initCameleon = function (data) {
        var _this = this;
        if (data === void 0) { data = null; }
        this.cameleonService.init(data)
            .then(function (result) {
            _this.router.navigate([_this.localize.translateRoute('/' + _this.applicationData.reference + '/' + _this.cameleonService.CP + '/' + _this.cameleonService.breakdownTree[0].name)]);
            _this.init();
        }, function (error) {
            _this.applicationState.loading = false;
            _this.modalService.open('modal-fatal-error', { title: 'UNE ERREUR EST SURVENUE', text: error.message.$ });
        }).catch(function (e) {
            console.log(e);
            _this.modalService.open('modal-fatal-error', { title: 'UNE ERREUR EST SURVENUE', text: 'Une erreur inconnue est survenue. Merci de fermer cette fenêtre et de recommencer une configuration.' });
            _this.applicationState.loading = false;
        });
    };
    /**
     * Init component
     */
    ConfigurationComponent.prototype.init = function () {
        if (this.FO) {
            this.cameleonService.keepAlive();
            this.currentFO = this.cameleonService.getFO(this.FO);
            this.applicationState.loading = false;
            this.update();
        }
        else {
            this.router.navigate([this.localize.translateRoute('/' + this.applicationData.reference + '/' + this.cameleonService.CP + '/' + this.cameleonService.breakdownTree[0].name)]);
        }
    };
    /**
     * Update component
     */
    ConfigurationComponent.prototype.update = function () {
        var _this = this;
        var targetId;
        if (document.activeElement && document.activeElement.id) {
            targetId = document.activeElement.id;
        }
        this.groups = [];
        var currentGroup = 0;
        this.groups[currentGroup] = [];
        var lastType;
        for (var _i = 0, _a = this.currentFO.fps; _i < _a.length; _i++) {
            var fp = _a[_i];
            if (fp.visible) {
                if ((fp.type == FPTypes.IMAGES || fp.type == FPTypes.CHECKBOXES) && lastType) {
                    currentGroup++;
                    this.groups[currentGroup] = [];
                }
                else if (lastType == FPTypes.IMAGES || lastType == FPTypes.CHECKBOXES) {
                    currentGroup++;
                    this.groups[currentGroup] = [];
                }
                this.groups[currentGroup].push(fp);
                lastType = fp.type;
            }
        }
        setTimeout(function () {
            if (_this.currentFO.completed && (_this.applicationData.autoNext && _this.currentFO.name == _this.cameleonService.breakdownTree[_this.cameleonService.breakdownTree.length - 1].name)) {
                _this.applicationState.loading = false;
                _this.router.navigate(_this.nextFOPath);
            }
            else {
                if (targetId) {
                    var target = document.getElementById(targetId);
                    if (target) {
                        target.focus();
                    }
                }
                imagesLoaded(_this.container.nativeElement, function (instance) {
                    _this.scrollToBottom();
                    _this.applicationState.loading = false;
                });
            }
        }, 100);
    };
    /**
       * Scroll to bottom
       */
    ConfigurationComponent.prototype.scrollToBottom = function () {
        var _this = this;
        if (this.scrollTimeout) {
            clearTimeout(this.scrollTimeout);
            this.scrollTimeout = null;
        }
        this.scrollTimeout = setTimeout(function () {
            var q = document.getElementsByClassName('question--failed');
            if (q.length == 0) {
                q = document.getElementsByClassName('question--wait');
            }
            if (q.length > 0) {
                var target = q[0];
                var offset = 0;
                do {
                    if (!isNaN(target.offsetTop)) {
                        offset += target.offsetTop;
                    }
                } while (target = target.offsetParent);
                try {
                    window.scroll({ left: 0, top: offset, behavior: 'smooth' });
                }
                catch (e) {
                    window.scrollTo(0, offset);
                }
            }
            else {
                document.documentElement.scrollTop = document.documentElement.scrollHeight;
            }
            _this.scrollTimeout = null;
        }, 50);
    };
    /**
     * Set value
     */
    ConfigurationComponent.prototype.setValue = function (data) {
        var _this = this;
        this.cameleonService.keepAlive();
        this.applicationState.loading = true;
        this.cameleonService.setValue(data)
            .then(function (result) {
            _this.cameleonService.getUserMessage(data).then(function (result) {
                var messages = [];
                for (var cpe in _this.cameleonService.messages) {
                    for (var _i = 0, _a = _this.cameleonService.messages[cpe]; _i < _a.length; _i++) {
                        var m = _a[_i];
                        //if (!m.read) {
                        messages.push(m.message);
                        //   m.read = true;
                        // }
                    }
                }
                if (_this.messages.length != messages.length) {
                    _this.displayMessage = true;
                }
                _this.messages = messages;
            });
            _this.cameleonService.keepAlive();
            _this.update();
        }, function (error) {
            console.log(error);
            _this.applicationState.loading = false;
            _this.modalService.open('modal-error', { title: 'ERREUR', text: error.message.$ });
        }).catch(function (e) {
            console.log(e);
            _this.modalService.open('modal-error', { title: 'ERREUR', text: 'Unknown error.' });
            _this.applicationState.loading = false;
        });
    };
    /**
     * Is form completed
     */
    ConfigurationComponent.prototype.formCompleted = function () {
        return this.currentFO ? this.currentFO.completed : false;
    };
    Object.defineProperty(ConfigurationComponent.prototype, "nextFOPath", {
        get: function () {
            for (var i = 0; i < this.cameleonService.breakdownTree.length; i++) {
                var fo = this.cameleonService.breakdownTree[i];
                if (i + 1 < this.cameleonService.breakdownTree.length) {
                    if (fo.name == this.FO) {
                        return [this.localize.translateRoute('/' + this.applicationData.reference + '/' + this.cameleonService.CP + '/' + this.cameleonService.breakdownTree[i + 1].name)];
                    }
                }
            }
            return [this.localize.translateRoute('/' + this.applicationData.reference + '/' + this.cameleonService.CP + '/prix')];
        },
        enumerable: true,
        configurable: true
    });
    ConfigurationComponent.prototype.ngOnDestroy = function () {
        this.sub.unsubscribe();
    };
    return ConfigurationComponent;
}());
export { ConfigurationComponent };
