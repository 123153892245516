import { EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '~/environments/environment';
import { SetConfigParameters } from './parameters/set-config.parameters';
import { RaiseEventParameters } from './parameters/raise-event.parameters';
import { GetCPStructureParameters } from './parameters/get-cp-structure.parameters';
import { GetFOStructureParameters } from './parameters/get-fo-structure.parameters';
import { GetFPStructureParameters } from './parameters/get-fp-structure.parameters';
import { GetBusinessValueParameters } from './parameters/get-business-value.parameters';
import { SaveConfigurationParameters } from './parameters/save-configuration.parameters';
import { FO } from './vo/FO.vo';
import { BV } from './vo/BV.vo';
import { WINPRO } from './vo/WINPRO.vo';
import { BrandTypes } from '~/environments/environment.types';
export var CameleonStates;
(function (CameleonStates) {
    CameleonStates[CameleonStates["INIT"] = 0] = "INIT";
    CameleonStates[CameleonStates["LOADING"] = 1] = "LOADING";
    CameleonStates[CameleonStates["READY"] = 2] = "READY";
    CameleonStates[CameleonStates["ERROR"] = 3] = "ERROR";
    CameleonStates[CameleonStates["FATALERROR"] = 4] = "FATALERROR";
})(CameleonStates || (CameleonStates = {}));
var CameleonService = /** @class */ (function () {
    /**
       * Constructor
       */
    function CameleonService(http) {
        var _this = this;
        this.http = http;
        this.WKS = 'WKS_Bouvet';
        this._CP = '';
        this.state = null;
        this.logDirty = false;
        this.onChange = new EventEmitter();
        this.onError = new EventEmitter();
        this.version = null;
        this.handleMessage = function (e) {
            if (e.data === "USER_CLOSE_IFRAME") {
                _this.deleteEngine();
                clearInterval(_this._pingInterval);
                //postMessage
                var data = {
                    from: 'configurator',
                    finish: true
                };
                parent.postMessage(JSON.stringify(data), "*");
            }
        };
        this.state = CameleonStates.INIT;
        if (environment.CAMELEON_USER) {
            this._httpOptions = {
                headers: new HttpHeaders({
                    'Authorization': 'Basic ' + btoa(environment.CAMELEON_USER + ':' + environment.CAMELEON_PWD),
                }),
                withCredentials: true
            };
        }
        else {
            this._httpOptions = {
                headers: new HttpHeaders({}),
                withCredentials: true
            };
        }
        this._httpOptions.headers.append('Access-Control-Allow-Credentials', 'true');
        this._httpOptions.headers.append('Content-Type', 'application/json');
    }
    Object.defineProperty(CameleonService.prototype, "CP", {
        get: function () {
            return this._CP;
        },
        set: function (value) {
            if (this._CP != value) {
                this.destroy();
                this._CP = value;
                this.state = CameleonStates.INIT;
            }
        },
        enumerable: true,
        configurable: true
    });
    /**
       * init
       */
    CameleonService.prototype.init = function (data) {
        var _this = this;
        //mettre la récéption du message de l'extranet
        window.addEventListener('message', this.handleMessage, false);
        this.state = CameleonStates.LOADING;
        this._bvLibrary = [];
        this.breakdownTree = [];
        this.messages = [];
        return new Promise(function (resolve, reject) {
            // Create engine
            _this.loadParameters().then(function (result) {
                _this.createEngine(result).then(function (result) {
                    _this.startPing();
                    // Set config
                    _this.setConfig(data).then(function (result) {
                        // Update settings
                        _this.updateSettings().then(function (result) {
                            // Raise event
                            _this.raiseEvent().then(function (result) {
                                // Get CP Structure
                                _this.getCPStructure().then(function (result) {
                                    // Get FO Structure
                                    _this.getFOStructure().then(function (result) {
                                        // Get current State
                                        _this.getCurrentState().then(function (result) {
                                            _this.state = CameleonStates.READY;
                                            resolve(true);
                                        }, function (error) {
                                            _this.state = CameleonStates.FATALERROR;
                                            reject(error);
                                        }).catch(function (e) { _this.unknownError(reject, e); });
                                    }, function (error) {
                                        _this.state = CameleonStates.FATALERROR;
                                        reject(error);
                                    }).catch(function (e) { _this.unknownError(reject, e); });
                                }, function (error) {
                                    _this.state = CameleonStates.FATALERROR;
                                    reject(error);
                                }).catch(function (e) { _this.unknownError(reject, e); });
                            }, function (error) {
                                _this.state = CameleonStates.FATALERROR;
                                reject(error);
                            }).catch(function (e) { _this.unknownError(reject, e); });
                        }, function (error) {
                            _this.state = CameleonStates.FATALERROR;
                            reject(error);
                        }).catch(function (e) { _this.unknownError(reject, e); });
                    }, function (error) {
                        _this.state = CameleonStates.FATALERROR;
                        reject(error);
                    }).catch(function (e) { _this.unknownError(reject, e); });
                }, function (error) {
                    _this.state = CameleonStates.FATALERROR;
                    reject(error);
                }).catch(function (e) { _this.unknownError(reject, e); });
            }, function (error) {
                _this.state = CameleonStates.FATALERROR;
                reject(error);
            }).catch(function (e) { _this.unknownError(reject, e); });
        });
    };
    /**
       * Create engine
       */
    CameleonService.prototype.loadParameters = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var filename;
            if (_this.version && _this.version < 20) {
                filename = environment.BRAND == BrandTypes.MINCO ? 'create-engine.parameters.legacy.json' : 'create-engine.parameters.legacy.json';
            }
            else {
                filename = environment.BRAND == BrandTypes.MINCO ? 'create-engine.parameters.json' : 'create-engine.parameters.json';
            }
            _this.http.get(environment.EXTRANET_SERVER + '/uploads/config/' + filename).subscribe(function (responseData) {
                var res = responseData;
                resolve(res);
            }, function (err) {
                _this.unknownError(reject);
            });
        });
    };
    /**
       * Create engine
       */
    CameleonService.prototype.createEngine = function (data) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.http.post(environment.CAMELEON_SERVER + environment.CAMELEON_API, data, _this._httpOptions).subscribe(function (responseData) {
                var res = responseData;
                if (res.CameleonResponse && res.CameleonResponse.exception) {
                    reject(res.CameleonResponse.exception);
                }
                else {
                    _this._sessionID = res.CameleonResponse.long;
                    resolve(res);
                }
            }, function (err) {
                _this.unknownError(reject);
            });
        });
    };
    /**
       * Set config
       */
    CameleonService.prototype.setConfig = function (data) {
        var _this = this;
        if (data) {
            data = {
                "parameters": {
                    "parameter": [{
                            "@name": "savedConfiguration",
                            "string": data
                        }, {
                            "@name": "overrideSessionSettings",
                            "boolean": true
                        }, {
                            "@name": "withException",
                            "boolean": true
                        }, {
                            "@name": "create",
                            "boolean": false
                        }]
                }
            };
        }
        else {
            data = JSON.parse(JSON.stringify(SetConfigParameters.defaults));
            data.parameters[0].ConfigurationSettings.Session.Param.find(function (row) {
                return (row["@cpe"] == "CPE.Settings.Session.Workspace");
            })["@value"] = this.WKS;
            data.parameters[0].ConfigurationSettings.Session.Param.push({
                '@cpe': 'CPE.Settings.Session.CPName',
                '@value': this.CP
            });
        }
        return new Promise(function (resolve, reject) {
            _this.http.put(environment.CAMELEON_SERVER + environment.CAMELEON_API + '/' + _this._sessionID, data, _this._httpOptions).subscribe(function (responseData) {
                var res = responseData;
                if (res.CameleonResponse && res.CameleonResponse.exception) {
                    reject(res.CameleonResponse.exception);
                }
                else {
                    var CPName = void 0;
                    if (res.CameleonResponse.ResultWithFailures) {
                        CPName = res.CameleonResponse.ResultWithFailures.Result.ConfiguratorSession.Settings.Session.filter(function (data) {
                            return data['@cpe'] == 'CPE.Settings.Session.CPName';
                        });
                    }
                    else {
                        CPName = res.CameleonResponse.ConfiguratorSession.Settings.Session.filter(function (data) {
                            return data['@cpe'] == 'CPE.Settings.Session.CPName';
                        });
                    }
                    if (CPName.length > 0) {
                        _this._CP = CPName[0]['@value'];
                    }
                    resolve(res);
                }
            }, function (err) {
                if (err.error.CameleonResponse.exception.message.$.indexOf('The handler of configurators type for ' + _this._sessionID) == 0) {
                    _this.state = CameleonStates.FATALERROR;
                    reject({
                        "type": "CookieException",
                        "message": {
                            "@criticity": "FATALERROR",
                            "$": "Votre navigateur semble bloquer les cookies tiers et ne vous permet pas d’accéder à notre configurateur produit.<br> Vous devez donc modifier son paramétrage.<br>\
              Par exemple sur Safari : «Préférences > Confidentialité > Empêcher le suivi sur plusieurs domaines»"
                        }
                    });
                }
                else {
                    _this.unknownError(reject);
                }
            });
        });
    };
    /**
       * Raise event
       */
    CameleonService.prototype.raiseEvent = function () {
        var _this = this;
        var data = JSON.parse(JSON.stringify(RaiseEventParameters.defaults));
        data.parameters[0].clientevent['$'] = 'CPE.' + this.WKS + '/CP/' + this.CP;
        return new Promise(function (resolve, reject) {
            _this.http.post(environment.CAMELEON_SERVER + environment.CAMELEON_API + '/' + _this._sessionID + '/raiseEvent', data, _this._httpOptions).subscribe(function (responseData) {
                var res = responseData;
                if (res && res.CameleonResponse && res.CameleonResponse.exception) {
                    reject(res.CameleonResponse.exception);
                }
                else {
                    resolve(res);
                }
            }, function (err) {
                _this.unknownError(reject);
            });
        });
    };
    /**
       * Update settings
       */
    CameleonService.prototype.updateSettings = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var filename;
            if (_this.version && _this.version < 20) {
                filename = environment.BRAND == BrandTypes.MINCO ? 'update-settings.parameters.minco.legacy.json' : 'update-settings.parameters.json';
            }
            else {
                filename = environment.BRAND == BrandTypes.MINCO ? 'update-settings.parameters.minco.json' : 'update-settings.parameters.json';
            }
            _this.http.get(environment.EXTRANET_SERVER + '/uploads/config/' + filename).subscribe(function (responseData) {
                var data;
                data = responseData;
                if (_this.CC) {
                    data.parameters[0].list.push({
                        "pair": {
                            "left": {
                                "cpe": "CPE.Settings.Session.Application.ParameterTab.CustomerId"
                            },
                            "right": {
                                "string": _this.CC
                            }
                        }
                    });
                }
                _this.http.patch(environment.CAMELEON_SERVER + environment.CAMELEON_API + '/' + _this._sessionID + '/settings', data, _this._httpOptions).subscribe(function (responseData) {
                    var res = responseData;
                    if (res && res.CameleonResponse && res.CameleonResponse.exception) {
                        reject(res.CameleonResponse.exception);
                    }
                    else {
                        resolve(res);
                    }
                }, function (err) {
                    _this.unknownError(reject);
                });
            }, function (err) {
                _this.unknownError(reject);
            });
        });
    };
    /**
       * Get FO structure
       */
    CameleonService.prototype.getFOStructure = function () {
        var _this = this;
        var data = JSON.parse(JSON.stringify(GetFOStructureParameters.defaults));
        for (var _i = 0, _a = this._foList; _i < _a.length; _i++) {
            var fo = _a[_i];
            data.parameters[0].list.push({ 'cpe': fo });
        }
        return new Promise(function (resolve, reject) {
            _this.http.post(environment.CAMELEON_SERVER + environment.CAMELEON_API + '/' + _this._sessionID + '/forms/search', data, _this._httpOptions).subscribe(function (responseData) {
                var res = responseData;
                if (res && res.CameleonResponse && res.CameleonResponse.exception) {
                    reject(res.CameleonResponse.exception);
                }
                else {
                    var tempBreakdownTree = [];
                    var ready = true;
                    var _loop_1 = function (fo) {
                        var lfo = _this.breakdownTree.filter(function (fol) { return (fol.id === fo['Form']['@cpe']); });
                        var cfo = void 0;
                        if (lfo.length > 0) {
                            cfo = lfo[0];
                            lfo[0].update(fo['Form']);
                        }
                        else {
                            if (fo.Form.Status['@exist'] == "true" && fo.Form['@name'] != 'FO_WinproAnswer' && fo.Form['@name'] != 'FO_DataBase') {
                                cfo = new FO(fo.Form);
                            }
                        }
                        if (cfo) {
                            tempBreakdownTree.push(cfo);
                            if (!cfo.completed) {
                                ready = false;
                            }
                        }
                    };
                    for (var _i = 0, _a = res.CameleonResponse.list; _i < _a.length; _i++) {
                        var fo = _a[_i];
                        _loop_1(fo);
                    }
                    _this.breakdownTree = tempBreakdownTree;
                    _this.completed = ready;
                    resolve(res);
                }
            }, function (err) {
                _this.unknownError(reject);
            });
        });
    };
    /**
       * Get CP structure
       */
    CameleonService.prototype.getCPStructure = function () {
        var _this = this;
        var data = JSON.parse(JSON.stringify(GetCPStructureParameters.defaults));
        data.parameters[0].list.push({ 'cpe': 'CPE.' + this.WKS + '/CP/' + this.CP });
        return new Promise(function (resolve, reject) {
            _this.http.post(environment.CAMELEON_SERVER + environment.CAMELEON_API + '/' + _this._sessionID + '/configurableProducts/search', data, _this._httpOptions).subscribe(function (responseData) {
                var res = responseData;
                if (res && res.CameleonResponse && res.CameleonResponse.exception) {
                    reject(res.CameleonResponse.exception);
                }
                else {
                    _this._foList = [];
                    for (var _i = 0, _a = res.CameleonResponse.list[0].ConfigurableProduct.Children; _i < _a.length; _i++) {
                        var fo = _a[_i];
                        _this._foList.push(fo.FormChild['@cpe']);
                    }
                    _this.productDescription = res.CameleonResponse.list[0].ConfigurableProduct.Description;
                    resolve(res);
                }
            }, function (err) {
                _this.unknownError(reject);
            });
        });
    };
    /**
       * Get current configuration state from Caméléon
       */
    CameleonService.prototype.getCurrentState = function () {
        var _this = this;
        var data = JSON.parse(JSON.stringify(GetFPStructureParameters.defaults));
        for (var _i = 0, _a = this.breakdownTree; _i < _a.length; _i++) {
            var fo = _a[_i];
            for (var _b = 0, _c = fo.fps; _b < _c.length; _b++) {
                var fp = _c[_b];
                data.parameters[0].list.push({ cpe: fp.id });
            }
        }
        return new Promise(function (resolve, reject) {
            _this.http.post(environment.CAMELEON_SERVER + environment.CAMELEON_API + '/' + _this._sessionID + '/formProperties/search', data, _this._httpOptions).subscribe(function (responseData) {
                var res = responseData;
                if (res && res.CameleonResponse && res.CameleonResponse.exception) {
                    reject(res.CameleonResponse.exception);
                }
                else {
                    for (var _i = 0, _a = _this.breakdownTree; _i < _a.length; _i++) {
                        var fo = _a[_i];
                        var _loop_2 = function (fp) {
                            var lfp = res.CameleonResponse.list.filter(function (fpl) { return (fpl.FormProperty['@cpe'] === fp.id); });
                            if (lfp.length > 0) {
                                fp.update(lfp[0]['FormProperty']);
                            }
                        };
                        for (var _b = 0, _c = fo.fps; _b < _c.length; _b++) {
                            var fp = _c[_b];
                            _loop_2(fp);
                        }
                    }
                    // Get current State
                    _this.getMissingBV().then(function (result) {
                        _this.state = CameleonStates.READY;
                        resolve(res);
                    }, function (error) {
                        _this.state = CameleonStates.FATALERROR;
                        reject(error);
                    }).catch(function (e) { _this.unknownError(reject, e); });
                }
            }, function (err) {
                _this.unknownError(reject);
            });
        });
    };
    /**
       * Get missing business values from Caméléon
       */
    CameleonService.prototype.getMissingBV = function () {
        var _this = this;
        var data = JSON.parse(JSON.stringify(GetBusinessValueParameters.defaults));
        //00console.log('test', data);
        for (var _i = 0, _a = this.breakdownTree; _i < _a.length; _i++) {
            var fo = _a[_i];
            for (var _b = 0, _c = fo.fps; _b < _c.length; _b++) {
                var fp = _c[_b];
                var _loop_3 = function (bv) {
                    if (bv.type == 'object') {
                        //console.log('bv ',bv);
                        var c = this_1._bvLibrary.filter(function (bvl) { return (bvl.cpe == bv.cpe); });
                        //console.log('c', data.parameters[0]);
                        if (c.length == 0) {
                            data.parameters[0].list.push({ cpe: bv.cpe });
                        }
                    }
                };
                var this_1 = this;
                for (var _d = 0, _e = fp.bvs; _d < _e.length; _d++) {
                    var bv = _e[_d];
                    _loop_3(bv);
                }
            }
        }
        return new Promise(function (resolve, reject) {
            if (data.parameters[0].list.length > 0) {
                _this.http.post(environment.CAMELEON_SERVER + environment.CAMELEON_API + '/' + _this._sessionID + '/businessValues/search', data, _this._httpOptions).subscribe(function (responseData) {
                    var res = responseData;
                    if (res && res.CameleonResponse && res.CameleonResponse.exception) {
                        reject(res.CameleonResponse.exception);
                    }
                    else {
                        for (var _i = 0, _a = res.CameleonResponse.list; _i < _a.length; _i++) {
                            var bv = _a[_i];
                            _this._bvLibrary.push(new BV(bv.BusinessValue));
                        }
                        resolve(res);
                    }
                }, function (err) {
                    _this.unknownError(reject);
                });
            }
            else {
                resolve(true);
            }
        });
    };
    /**
       * Get FO breakdown
       */
    CameleonService.prototype.getFO = function (id) {
        var _this = this;
        //    console.log(this.breakdownTree);
        return this.breakdownTree.filter(function (fo) { return (fo.id === 'CPE.' + _this.WKS + '/CP/' + _this.CP + '.' + _this.WKS + '/FO/' + id); })[0];
    };
    /**
       * Get BV description
       */
    CameleonService.prototype.getBVDescription = function (bv) {
        if (bv.description) {
            return bv.description;
        }
        var desc = this._bvLibrary.filter(function (bvl) { return (bvl.id === bv.id); });
        return desc.length > 0 ? desc[0].description : bv.description;
    };
    /**
       * Get BV infos
       */
    CameleonService.prototype.getBVInfos = function (bv) {
        var desc = this._bvLibrary.filter(function (bvl) { return (bvl.id === bv.id); });
        return desc.length > 0 ? desc[0].infos : null;
    };
    /**
       * Get BV image
       */
    CameleonService.prototype.getBVImage = function (id) {
        var img = this._bvLibrary.filter(function (bv) { return (bv.id === id); });
        return img.length > 0 ? img[0].image : null;
    };
    /**
       * Set Value
       */
    CameleonService.prototype.setValue = function (data) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.http.post(environment.CAMELEON_SERVER + environment.CAMELEON_API + '/' + _this._sessionID + '/valuations', data, _this._httpOptions).subscribe(function (responseData) {
                var res = responseData;
                if (res && res.CameleonResponse && res.CameleonResponse.exception) {
                    reject(res.CameleonResponse.exception);
                }
                else {
                    if (res.CameleonResponse.Failures.length > 0) {
                        var errorMessage = "";
                        for (var _i = 0, _a = res.CameleonResponse.Failures; _i < _a.length; _i++) {
                            var err = _a[_i];
                            errorMessage += (err.SetValuationFailure.Message.TranslatedMessage) + '<br/>';
                        }
                        reject({
                            "type": "CameleonException",
                            "message": {
                                "@criticity": "ERROR",
                                "$": errorMessage
                            }
                        });
                    }
                    else {
                        _this.logDirty = true;
                        // Get FO Structure
                        _this.getFOStructure().then(function (result) {
                            // Get current State
                            _this.getCurrentState().then(function (result) {
                                resolve(true);
                            }, function (error) {
                                _this.state = CameleonStates.FATALERROR;
                                reject(error);
                            }).catch(function (e) { _this.unknownError(reject, e); });
                        }, function (error) {
                            _this.state = CameleonStates.FATALERROR;
                            reject(error);
                        }).catch(function (e) { _this.unknownError(reject, e); });
                    }
                }
            }, function (err) {
                _this.unknownError(reject);
            });
        });
    };
    /**
       * Set Value
       */
    CameleonService.prototype.getUserMessage = function (data) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.http.get(environment.CAMELEON_SERVER + environment.CAMELEON_API + '/' + _this._sessionID + '/objects/' + data.parameters[0].list[0].pair.left.cpe + '/userMessages', _this._httpOptions).subscribe(function (responseData) {
                var res = responseData;
                if (res && res.CameleonResponse && res.CameleonResponse.exception) {
                    reject(res.CameleonResponse.exception);
                }
                else {
                    if (res && res.CameleonResponse && res.CameleonResponse.list) {
                        if (res.CameleonResponse.list.length > 0) {
                            _this.messages[data.parameters[0].list[0].pair.left.cpe] = [];
                            for (var _i = 0, _a = res.CameleonResponse.list; _i < _a.length; _i++) {
                                var message = _a[_i];
                                _this.messages[data.parameters[0].list[0].pair.left.cpe].push({
                                    read: false,
                                    message: message.UserMessage.TranslatedMessage
                                });
                            }
                        }
                        else {
                            delete _this.messages[data.parameters[0].list[0].pair.left.cpe];
                        }
                    }
                    resolve(_this.messages);
                }
            }, function (err) {
                _this.unknownError(reject);
            });
        });
    };
    /**
       * Get result
       */
    CameleonService.prototype.getResult = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            // Trigger Winpro
            _this.RESULT = new WINPRO();
            _this.triggerWinpro().then(function (result) {
                if (result) {
                    // Get WinPRO message
                    _this.getWinProMessage().then(function (result) {
                        // Get product price
                        _this.getWinProData('FP_PrixMenuiserie').then(function (result) {
                            _this.RESULT.productPrice = result.convertedNumericValue['@refValue'];
                            // Get product discount
                            _this.getWinProData('FP_RemisePrixMenuiserie').then(function (result) {
                                _this.RESULT.productDiscount = result.integerValue;
                                // Get RS price
                                _this.getWinProData('FP_PrixVolet').then(function (result) {
                                    _this.RESULT.rsPrice = result.convertedNumericValue['@refValue'];
                                    // Get RS discount
                                    _this.getWinProData('FP_RemisePrixVolet').then(function (result) {
                                        _this.RESULT.rsDiscount = result.integerValue;
                                        // Get Drawing
                                        _this.getWinProData('FP_DrawingPath1').then(function (result) {
                                            _this.RESULT.drawingPath = result.longTextValue;
                                            // Get Drawing
                                            _this.getWinProData('FP_QuoteId').then(function (result) {
                                                _this.RESULT.quoteId = result.textValue.replace('.jpg', '');
                                                // Get Generative Process
                                                _this.getGenerativeProcess().then(function (result) {
                                                    _this.RESULT.generativeProcess = result;
                                                    resolve(_this.RESULT);
                                                }, function (error) {
                                                    _this.state = CameleonStates.FATALERROR;
                                                    reject(error);
                                                }).catch(function (e) { _this.unknownError(reject, e); });
                                            }, function (error) {
                                                _this.state = CameleonStates.FATALERROR;
                                                reject(error);
                                            }).catch(function (e) { _this.unknownError(reject, e); });
                                        }, function (error) {
                                            _this.state = CameleonStates.FATALERROR;
                                            reject(error);
                                        }).catch(function (e) { _this.unknownError(reject, e); });
                                    }, function (error) {
                                        _this.state = CameleonStates.FATALERROR;
                                        reject(error);
                                    }).catch(function (e) { _this.unknownError(reject, e); });
                                }, function (error) {
                                    _this.state = CameleonStates.FATALERROR;
                                    reject(error);
                                }).catch(function (e) { _this.unknownError(reject, e); });
                            }, function (error) {
                                _this.state = CameleonStates.FATALERROR;
                                reject(error);
                            }).catch(function (e) { _this.unknownError(reject, e); });
                        }, function (error) {
                            _this.state = CameleonStates.FATALERROR;
                            reject(error);
                        }).catch(function (e) { _this.unknownError(reject, e); });
                    }, function (error) {
                        //this.state = CameleonStates.FATALERROR;
                        reject(error);
                    }).catch(function (e) { _this.unknownError(reject, e); });
                }
                else {
                    _this.state = CameleonStates.ERROR;
                    _this.unknownError(reject);
                }
            }, function (error) {
                _this.state = CameleonStates.FATALERROR;
                reject(error);
            }).catch(function (e) { _this.unknownError(reject, e); });
        });
    };
    /**
     * Trigger WINPRO
     */
    CameleonService.prototype.triggerWinpro = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.http.get(environment.CAMELEON_SERVER + environment.CAMELEON_API + '/' + _this._sessionID + '/formProperties/CPE.' + _this.WKS + '/CP/' + _this.CP + '.' + _this.WKS + '/FO/FO_WinproAnswer.FP/FP_Declencheur', _this._httpOptions).subscribe(function (responseData) {
                var res = responseData;
                if (res.CameleonResponse && res.CameleonResponse.exception) {
                    reject(res.CameleonResponse.exception);
                }
                else {
                    if (res
                        && res.CameleonResponse
                        && res.CameleonResponse.FormProperty
                        && res.CameleonResponse.FormProperty.SingleValuation.Value) {
                        resolve(res.CameleonResponse.FormProperty.SingleValuation.Value.booleanValue == "true");
                    }
                    else {
                        _this.unknownError(reject);
                    }
                }
            }, function (err) {
                _this.unknownError(reject);
            });
        });
    };
    /**
     * Get Winpro message
     */
    CameleonService.prototype.getWinProMessage = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.http.get(environment.CAMELEON_SERVER + environment.CAMELEON_API + '/' + _this._sessionID + '/formProperties/CPE.' + _this.WKS + '/CP/' + _this.CP + '.' + _this.WKS + '/FO/FO_WinproAnswer.FP/FP_ErrorMessage', _this._httpOptions).subscribe(function (responseData) {
                var res = responseData;
                if (res
                    && res.CameleonResponse
                    && res.CameleonResponse.FormProperty
                    && res.CameleonResponse.FormProperty.SingleValuation.Value) {
                    if (res.CameleonResponse.FormProperty.SingleValuation.Value.longTextValue == 'NONE') {
                        resolve(true);
                    }
                    else {
                        reject({
                            message: {
                                $: res.CameleonResponse.FormProperty.SingleValuation.Value.longTextValue
                            }
                        });
                    }
                }
            }, function (err) {
                _this.unknownError(reject);
            });
        });
    };
    /**
     * Get Winpro data
     */
    CameleonService.prototype.getWinProData = function (cpe) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.http.get(environment.CAMELEON_SERVER + environment.CAMELEON_API + '/' + _this._sessionID + '/formProperties/CPE.' + _this.WKS + '/CP/' + _this.CP + '.' + _this.WKS + '/FO/FO_WinproAnswer.FP/' + cpe, _this._httpOptions).subscribe(function (responseData) {
                var res = responseData;
                if (res.CameleonResponse && res.CameleonResponse.exception) {
                    reject(res.CameleonResponse.exception);
                }
                else {
                    resolve(res.CameleonResponse.FormProperty.SingleValuation.Value);
                }
            }, function (err) {
                _this.unknownError(reject);
            });
        });
    };
    /**
     * Get Generative Process
     */
    CameleonService.prototype.getGenerativeProcess = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.http.get(environment.CAMELEON_SERVER + environment.CAMELEON_API + '/' + _this._sessionID + '/generativeProcessesResult', _this._httpOptions).subscribe(function (responseData) {
                var res = responseData;
                console.log(responseData);
                if (res.CameleonResponse && res.CameleonResponse.exception) {
                    reject(res.CameleonResponse.exception);
                }
                else {
                    var salesBreakdown = [];
                    for (var _i = 0, _a = res.CameleonResponse.GenerativeProcessesResult.SalesBreakdownTree[0].Sig[0].BusinessPropertySets[0].BusinessProperties; _i < _a.length; _i++) {
                        var entry = _a[_i];
                        if (entry.SingleBusinessProperty.Value && entry.SingleBusinessProperty.Value.textValue != '#NA#') {
                            var label = entry.SingleBusinessProperty["@name"].replace(/^IFQ_/, '');
                            label = label.replace(/_/g, ' ');
                            salesBreakdown.push({
                                label: label,
                                value: entry.SingleBusinessProperty.Value.textValue,
                                mandatory: (entry.SingleBusinessProperty["@name"].match(/^IFQ_/) == null) // Ignore For Quotation
                            });
                        }
                    }
                    resolve(salesBreakdown);
                }
            }, function (err) {
                _this.unknownError(reject);
            });
        });
    };
    /**
     * Save configuration
     */
    CameleonService.prototype.save = function () {
        var _this = this;
        var data = JSON.parse(JSON.stringify(SaveConfigurationParameters.defaults));
        return new Promise(function (resolve, reject) {
            _this.http.post(environment.CAMELEON_SERVER + environment.CAMELEON_API + '/' + _this._sessionID + '/saveConfiguration', data, _this._httpOptions).subscribe(function (responseData) {
                var res = responseData;
                if (res.CameleonResponse && res.CameleonResponse.exception) {
                    reject(res.CameleonResponse.exception);
                }
                else {
                    resolve(res.CameleonResponse.string);
                }
            }, function (err) {
                _this.unknownError(reject);
            });
        });
    };
    /**
     * Send Config
     */
    CameleonService.prototype.sendToExtranet = function (data) {
        var _this = this;
        var httpOptions = {
            headers: new HttpHeaders({}),
            withCredentials: true
        };
        return new Promise(function (resolve, reject) {
            _this.http.post(environment.EXTRANET_SERVER + environment.EXTRANET_API + '/quote-item', data, httpOptions).subscribe(function (responseData) {
                var res = responseData;
                resolve(true);
            }, function (err) {
                _this.unknownError(reject);
            });
        });
    };
    /**
     * Get Config
     */
    CameleonService.prototype.getDataFromExtranet = function (itemId) {
        var _this = this;
        var httpOptions = {
            headers: new HttpHeaders({})
        };
        return new Promise(function (resolve, reject) {
            _this.http.get(environment.EXTRANET_SERVER + environment.EXTRANET_API + '/quote-item-configuration/' + itemId, httpOptions).subscribe(function (responseData) {
                var res = responseData;
                resolve(res);
            }, function (err) {
                _this.unknownError(reject);
            });
        });
    };
    /**
       * Reject with Unknown Error
       */
    CameleonService.prototype.unknownError = function (reject, e) {
        if (e === void 0) { e = null; }
        this.state = CameleonStates.FATALERROR;
        reject({
            "type": "RuntimeException",
            "message": {
                "@criticity": "FATALERROR",
                "$": "Une erreur inconnue est survenue. Merci de fermer cette fenêtre et de recommencer une configuration."
            }
        });
    };
    /**
     * Delete engine
     */
    CameleonService.prototype.deleteEngine = function () {
        var client = new XMLHttpRequest();
        client.open("DELETE", environment.CAMELEON_SERVER + environment.CAMELEON_API + '/' + this._sessionID, true); // third parameter indicates synchronous ajax
        if (environment.CAMELEON_USER) {
            client.setRequestHeader("Authorization", "Basic " + btoa(environment.CAMELEON_USER + ":" + environment.CAMELEON_PWD));
        }
        client.setRequestHeader('Content-Type', 'application/json');
        client.withCredentials = true;
        client.send();
    };
    /**
       * Start Ping
       */
    CameleonService.prototype.startPing = function () {
        var _this = this;
        this._lastActivity = new Date();
        this._pingInterval = setInterval(function () {
            if ((new Date().getTime() - _this._lastActivity.getTime()) / 1000 > environment.KEEP_ALIVE) {
                _this.onError.emit('timeout');
                _this.state = CameleonStates.FATALERROR;
                clearInterval(_this._pingInterval);
            }
            else {
                _this.http.put(environment.CAMELEON_SERVER + environment.CAMELEON_API + '/' + _this._sessionID + '/ping', null, _this._httpOptions).subscribe(function (responseData) {
                    var res = responseData;
                    if (res && res.CameleonResponse && res.CameleonResponse.exception) {
                        _this.state = CameleonStates.FATALERROR;
                        _this.onError.emit('timeout');
                        clearInterval(_this._pingInterval);
                    }
                    else {
                    }
                }, function (err) {
                    _this.state = CameleonStates.FATALERROR;
                    _this.onError.emit('timeout');
                    clearInterval(_this._pingInterval);
                });
            }
        }, 60 * 1000);
    };
    /**
     * Log error
     */
    CameleonService.prototype.log = function (data) {
        var _this = this;
        var httpOptions = {
            headers: new HttpHeaders({})
        };
        if (this.logDirty) {
            if (data.datas === null) {
                this.save()
                    .then(function (result) {
                    data.datas = result;
                    _this.http.post(environment.EXTRANET_SERVER + environment.EXTRANET_API + '/log-configuration', data, httpOptions).subscribe(function (responseData) {
                        _this.logDirty = false;
                    });
                }, function (err) {
                    console.log(err);
                });
            }
            else {
                this.http.post(environment.EXTRANET_SERVER + environment.EXTRANET_API + '/log-configuration', data, httpOptions).subscribe(function (responseData) {
                    _this.logDirty = false;
                });
            }
        }
    };
    /**
       * Keep alive
       */
    CameleonService.prototype.keepAlive = function () {
        this._lastActivity = new Date();
    };
    /**
       * Destroy
       */
    CameleonService.prototype.destroy = function () {
        if (this._sessionID) {
            this.deleteEngine();
            this._sessionID = null;
            this.breakdownTree = [];
            this.messages = [];
            this.completed = false;
            clearInterval(this._pingInterval);
        }
        this.state = CameleonStates.INIT;
    };
    return CameleonService;
}());
export { CameleonService };
