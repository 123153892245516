
<div #container class="winpro" *ngIf="data">
  <div class="winpro__image">
    <img [src]="data.drawingPath" alt="">
  </div>
  <div class="winpro__result">
    <div class="winpro__title">Votre prix d'achat HT</div>
    <!--<div class="winpro__price"> {{ applicationData.adherentCode ? data.totalPriceWithoutDiscount : data.totalPrice | currency:'EUR':'symbol-narrow':'1.2' }}</div>-->
    <div class="winpro__price"> {{ applicationData.adherentCode ? data.totalPriceWithoutDiscount : data.totalPriceWithoutDiscount | currency:'EUR':'symbol-narrow':'1.2' }}</div>
    <button class="question button button--next" (click)="save()">Ajouter cette menuiserie</button>
  </div>
</div>

<div #container class="winpro" *ngIf="error">
  <div class="">
    <div class="winpro__title">Une erreur est survenue durant votre configuration</div>
    <div class="winpro__price">{{ error }}</div>
  </div>
</div>
