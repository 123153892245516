<div class="home">

  <div class="heading">
    <div class="heading__title">Repère : {{ applicationData.reference }}</div>
  </div>

  <ul class="navbar" *ngIf="cameleonService.state == 2">
    <ng-container *ngFor="let FO of cameleonService.breakdownTree">
      <li
          (click)='this.applicationData.autoNext = false'
          [routerLink]="['/', applicationData.reference, cameleonService.CP, FO.name] | localize"
          [routerLinkActive]="['navbar__item--current']"
          [ngClass]="{
            'navbar__item' : true,
            'navbar__item--disabled': !FO.visible,
            'navbar__item--passed': FO.completed
          }">
        <a class="navbar__link"><span class="navbar__text">{{ FO.description }}</span></a>
      </li>
      <li class="navbar__sep">
      </li>
    </ng-container>
    <li
      (click)='this.applicationData.autoNext = false'
      [routerLink]="['/', applicationData.reference, cameleonService.CP, 'prix'] | localize"
      [routerLinkActive]="['navbar__item--current']"
      [ngClass]="{
      'navbar__item' : true,
      'navbar__item--disabled': !(cameleonService.completed)
    }">
      <a class="navbar__link"><span class="navbar__text">Prix d’achat</span></a>
    </li>
  </ul>

  <div class="container">
    <router-outlet></router-outlet>
  </div>

</div>

<app-modal id="modal-message" width="640" #mm>
	<p class="_title-2" translate>Informations</p>
  <p *ngFor="let message of mm.data?.messages" [innerHtml]="'• '+(message | translate)"></p>
</app-modal>
