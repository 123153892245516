import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, HostListener } from '@angular/core';

import { CameleonService } from '~/app/shared/cameleon/cameleon.service';

@Component({
  selector: 'checkbox-group',
  templateUrl: './checkbox-group.component.html',
  host: {
    class: 'checkbox-group'
  }
})
export class CheckboxGroupComponent implements OnInit, OnDestroy {

  private _json:any = {
    "parameters": [
      {
        "@name": "valuations",
        "list": [
          {
            "pair": {
              "left": {
                "cpe": ""
              },
              "right": {
                "ValuationList": {
                  "@isCommentUserChoice": "false",
                  "@isQtyUserChoice": "false",
                  "@isValueUserChoice": "true",
                  "Value":  [
                  ],
                  "Comment": null
                }
              }
            }
          }
        ]
      }
    ]
  };

  /**
   * Data
   */
  private _fp:any;
  @Input()
  set fp(data: any) {
    this._value = {};
    console.log(data.value);
    for (let bv of data.bvs) {
      console.log(bv);
      this._value[bv.id] = {
        checked: data.value && data.value.findIndex(val => val.includes(bv.id)) > -1
      }
    }

    this._fp = data;
    this._json.parameters[0].list[0].pair.left.cpe = this.fp.id;
  }
  get fp(): any { return this._fp; }


  _value:any = {};
  set value(data:any) {
    this._value = data;
  }
  get value():any { return this._value; }

  @Output() onChange = new EventEmitter<any>();

  /**
	 * Constructor
	 */
  constructor(
    public cameleonService:CameleonService,
  ) {

  }

	/**
	 * On init
	 */
	ngOnInit() {

	}

  /**
	 * Ready
	 */
	get ready():boolean {
    for (let bv in this.value) {
      if (this.value[bv].checked) {
        return true;
      }
    }

    return false;
  }

  /**
	 * Submit
	 */
	submit() {
    this._json.parameters[0].list[0].pair.right.ValuationList.Value = [];
    for (let bv in this.value) {
      if (this.value[bv].checked) {
        this._json.parameters[0].list[0].pair.right.ValuationList.Value.push({"pkValue" : bv});
      }
    }
    this.onChange.emit(this._json);
	}

	/**
	* On destroy
	*/
	ngOnDestroy() {

	}
}
