import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, HostListener } from '@angular/core';

import { CameleonService } from '~/app/shared/cameleon/cameleon.service';

@Component({
  selector: 'textinput',
  templateUrl: './textinput.component.html',
  host: {
    class: 'textinput'
  }
})
export class TextInputComponent implements OnInit, OnDestroy {

  private _json:any = {
    "parameters": [
      {
        "@name": "valuations",
        "list": [
          {
            "pair": {
              "left": {
                "cpe": ""
              },
              "right": {
                "SingleValuation": {
                  "@isCommentUserChoice": "false",
                  "@isQtyUserChoice": "false",
                  "@isValueUserChoice": "true",
                  "Value": {
                    "@userQty": null,
                    "integerValue": ""
                  },
                  "Comment": null
                }
              }
            }
          }
        ]
      }
    ]
  };

  /**
   * Data
   */
  private _fp:any;
  @Input()
  set fp(data: any) {
    this._fp = data;
    this._json.parameters[0].list[0].pair.left.cpe = this.fp.id;

    this._value = this.fp.value;
    this._oldvalue = this.fp.value;
    this._json.parameters[0].list[0].pair.right.SingleValuation.Value.integerValue = this._value;
  }
  get fp(): any { return this._fp; }


  _oldvalue:string;
  _value:string;
  set value(data:string) {
    this._value = data;
    this._json.parameters[0].list[0].pair.right.SingleValuation.Value.integerValue = data;
  }
  get value(): string { return this._value; }

  @Output() onChange = new EventEmitter<any>();

  /**
	 * Constructor
	 */
  constructor(
    public cameleonService:CameleonService,
  ) {

  }

	/**
	 * On init
	 */
	ngOnInit() {

	}

  /**
	 * On blur
	 */
	onBlur() {
    if (this.value !== null && this._oldvalue !== this.value) {
      this._oldvalue = this.value;
      this.onChange.emit(this._json);
    }

    if (this.value === null) {
       this.value = this._oldvalue;
    }
	}

  /**
	 * On key down
	 */
  onKeydown(event) {
    if (event.key === "Enter" && this.value !== null && this._oldvalue !== this.value) {
      this._oldvalue = this.value;
      this.onChange.emit(this._json);
    }
  }

	/**
	* On destroy
	*/
	ngOnDestroy() {

	}
}
