<main [@appTransition]="getState(o)" *ngIf="applicationState.state != ApplicationStates.ERROR">
  <router-outlet #o="outlet"></router-outlet>
</main>

<app-modal id="modal-error" width="640" #me>
	<p class="_title-2" *ngIf="me.data?.title" translate>{{me.data?.title}}</p>
  <p *ngIf="me.data?.text" [innerHtml]="me.data?.text | translate"></p>
</app-modal>

<app-modal id="modal-fatal-error" width="640" blocking="true" #mfe>
	<p class="_title-2" *ngIf="mfe.data?.title" translate>{{mfe.data?.title}}</p>
  <p *ngIf="mfe.data?.text" [innerHtml]="mfe.data?.text | translate"></p>
</app-modal>

<app-loader *ngIf="applicationState.loading" [label]="applicationState.loadingLabel"><app-loader>
