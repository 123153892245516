var BV = /** @class */ (function () {
    function BV(data, type) {
        if (data === void 0) { data = null; }
        if (type === void 0) { type = null; }
        this._type = 'object';
        if (type == 'com.cameleon.framework.business.propertyvalue.IntegerValue') {
            this._type = 'integer';
        }
        if (type == 'com.cameleon.framework.business.propertyvalue.BooleanValue') {
            this._type = 'boolean';
        }
        if (type == 'com.cameleon.framework.business.propertyvalue.TextValue') {
            this._type = 'text';
        }
        if (data) {
            this.update(data);
        }
    }
    BV.prototype.update = function (data) {
        if (data['$']) {
            var tid = data['$'];
            var customBV = data['$'].match(/\/descr\=(.*)/);
            if (customBV) {
                tid = data['$'].replace(/\/descr\=(.*)/g, '');
                this._description = customBV[1];
                //this._type = 'string';
            }
            this._id = tid;
            if (this._type == 'boolean') {
                this._cpe = tid;
                this._description = tid == "true" ? 'Oui' : 'Non';
            }
            else if (this._type == 'text') {
                this._cpe = tid;
                this._description = tid;
            }
            else {
                this._cpe = 'CPE.' + tid;
            }
            this._authorized = data['@status'] == "AUTHORIZED";
        }
        if (data['@cpe']) {
            this._id = data['@cpe'].replace('CPE.', '');
            this._cpe = data['@cpe'];
            this._description = data.Description;
        }
        if (data['RMO'] && data['RMO']['RMOImage'] && data['RMO']['RMOImage'][0]) {
            this._image = data['RMO']['RMOImage'][0]['@onImage'];
        }
        if (data['RMO'] && data['RMO']['RMOText'] && data['RMO']['RMOText'][0]) {
            this._infos = data['RMO']['RMOText'][0]['Text'].replace(/(\r\n|\n|\r)/g, '<br/>');
        }
        this._rawData = data;
    };
    Object.defineProperty(BV.prototype, "id", {
        get: function () {
            return this._id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BV.prototype, "cpe", {
        get: function () {
            return this._cpe;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BV.prototype, "description", {
        get: function () {
            return this._description;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BV.prototype, "infos", {
        get: function () {
            return this._infos;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BV.prototype, "image", {
        get: function () {
            return this._image;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BV.prototype, "type", {
        get: function () {
            return this._type;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BV.prototype, "authorized", {
        get: function () {
            return this._authorized;
        },
        enumerable: true,
        configurable: true
    });
    return BV;
}());
export { BV };
