/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./winpro.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./winpro.component";
import * as i4 from "../shared/cameleon/cameleon.service";
import * as i5 from "../shared/data/application.state";
import * as i6 from "../shared/data/application.data";
import * as i7 from "../shared/modal/modal.service";
import * as i8 from "@angular/router";
import * as i9 from "localize-router/src/localize-router.service";
var styles_WinproComponent = [i0.styles];
var RenderType_WinproComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WinproComponent, data: {} });
export { RenderType_WinproComponent as RenderType_WinproComponent };
function View_WinproComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["container", 1]], null, 10, "div", [["class", "winpro"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "winpro__image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [["class", "winpro__result"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "winpro__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Votre prix d'achat HT"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "winpro__price"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", ""])), i1.ɵppd(8, 4), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "question button button--next"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Ajouter cette menuiserie"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.drawingPath; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.applicationData.adherentCode ? _co.data.totalPriceWithoutDiscount : i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v.parent, 0), _co.data.totalPriceWithoutDiscount, "EUR", "symbol-narrow", "1.2"))); _ck(_v, 7, 0, currVal_1); }); }
function View_WinproComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["container", 1]], null, 5, "div", [["class", "winpro"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "winpro__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Une erreur est survenue durant votre configuration"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "winpro__price"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.error; _ck(_v, 5, 0, currVal_0); }); }
export function View_WinproComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.CurrencyPipe, [i1.LOCALE_ID]), i1.ɵqud(671088640, 1, { container: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WinproComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WinproComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.error; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_WinproComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-winpro", [["class", ""]], null, null, null, View_WinproComponent_0, RenderType_WinproComponent)), i1.ɵdid(1, 245760, null, 0, i3.WinproComponent, [i4.CameleonService, i5.ApplicationState, i6.ApplicationData, i7.ModalService, i8.ActivatedRoute, i8.Router, i9.LocalizeRouterService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WinproComponentNgFactory = i1.ɵccf("app-winpro", i3.WinproComponent, View_WinproComponent_Host_0, {}, {}, []);
export { WinproComponentNgFactory as WinproComponentNgFactory };
