export const SaveConfigurationParameters = {
  defaults: {
    "parameters": [
      {
        "@name": "savingPolicy",
        "value": {
          "SavingPolicy": {
            "@type": "Full"
          }
        }
      }
    ]
  }
}
