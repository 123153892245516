/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./modal.component";
import * as i4 from "./modal.service";
var styles_ModalComponent = [i0.styles];
var RenderType_ModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalComponent, data: { "animation": [{ type: 7, name: "visibilityChanged", definitions: [{ type: 0, name: "*", styles: { type: 6, styles: { opacity: 1 }, offset: null }, options: undefined }, { type: 0, name: "hidden", styles: { type: 6, styles: { opacity: 0 }, offset: null }, options: undefined }, { type: 1, expr: "shown => hidden", animation: { type: 4, styles: null, timings: "0.6s cubic-bezier(.75,-0.48,.26,1.52)" }, options: null }, { type: 1, expr: "hidden => shown", animation: { type: 4, styles: null, timings: "0.6s cubic-bezier(.75,-0.48,.26,1.52)" }, options: null }], options: {} }] } });
export { RenderType_ModalComponent as RenderType_ModalComponent };
function View_ModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "modal__body__close-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "icon-close"]], null, null, null, null, null))], null, null); }
export function View_ModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "modal__body"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "modal__background"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getStyles(); _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.blocking; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal", [], [[40, "@visibilityChanged", 0]], [["component", "@visibilityChanged.start"], ["component", "@visibilityChanged.done"]], function (_v, en, $event) { var ad = true; if (("component:@visibilityChanged.start" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).animationStarted($event) !== false);
        ad = (pd_0 && ad);
    } if (("component:@visibilityChanged.done" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).animationEnded($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_ModalComponent_0, RenderType_ModalComponent)), i1.ɵdid(1, 245760, null, 0, i3.ModalComponent, [i4.ModalService, i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).visiblityState; _ck(_v, 0, 0, currVal_0); }); }
var ModalComponentNgFactory = i1.ɵccf("app-modal", i3.ModalComponent, View_ModalComponent_Host_0, { id: "id", width: "width", blocking: "blocking", onClose: "onClose" }, { onOK: "onOK" }, ["*"]);
export { ModalComponentNgFactory as ModalComponentNgFactory };
