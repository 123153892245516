import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Output, EventEmitter, ViewChild } from '@angular/core';
import { LocalizeRouterService } from 'localize-router';

import { CameleonService } from '~/app/shared/cameleon/cameleon.service';
import { ApplicationData } from '~/app/shared/data/application.data';
import { environment } from '~/environments/environment';

@Component({
    moduleId: module.id,
    selector: 'app-setup',
    templateUrl: 'setup.component.html',
    styleUrls: ['setup.component.scss'],
    host: {
        'class': ''
    },
})

export class SetupComponent {

    public data:any = [
        
    ];

    public refPattern = /^[A-Z0-9]{1,6}$/;

    public displayRef = true;
    public displayProduct = false;

    private _category:any;
    get category() { return this._category };
    set category(value: any) {
        this._category = value;

        if (this.category.products && this.category.products.length > 0) {
            this.product = this.category.products[0].value;
        } else {
            this.product = this.category.value;
        }
    }

    private _product:any;
    get product() { return this._product };
    set product(value: any) {
        this._product = value;
    }

    private _reference:any;
    get reference() { return this._reference };
    set reference(value: any) {
        this._reference = value;

        if (this.isReferenceValid) {
            this.messages = [];
            this.displayMessage = false;
        } else {
            this.messages = ["Votre repère doit comporter 6 caractères alphanumériques maximum, pas de caractères spéciaux ni d'espace."];
            this.displayMessage = true;
        }
    }
    
    public displayMessage:boolean = false;
    public messages:any = [];

    /**
     * Constructor
     */
    constructor(
        public cameleonService:CameleonService,
        public applicationData:ApplicationData,
        private localize: LocalizeRouterService,
        private http: HttpClient
    ) {

    }

    /**
     * On init
     */
    ngOnInit() {
        if (this.applicationData.reference == 'null') {
            this.applicationData.reference = null
        }

        if (this.applicationData.reference) {
            this.reference = this.applicationData.reference;
            this.displayRef = false;
        }

        if (this.cameleonService.CP == 'null') {
            this.cameleonService.CP = '';
        }

        let httpOptions = {
            headers: new HttpHeaders({
              'Authorization': 'Basic ' + btoa(environment.WS_USER + ':' + environment.WS_PWD),
            }),
            withCredentials: true
        };

        this.http.get(environment.WS_SERVER + environment.WS_API + '/Gamme/', httpOptions).subscribe((responseData) => {
            this.displayProduct = true;
            
            const res: any = responseData;
            res.ITEMS.sort((a, b) => {
                return a['@Order'] - b['@Order'];
            })
            for (let range of res.ITEMS) {
                let data = {
                    "label": range['@Label'],
                    "value": range['@Id'],
                    "products": [
                    ]
                };
                
                for (let product of range.Item) {
                    data.products.push({
                        "label": product['@Label'],
                        "value": product['@Id'],
                    });
                }

                this.data.push(data);
            }

            this.category = this.data[0];

            if (this.cameleonService.CP && this.cameleonService.CP.length > 0) {
                for (let cat of this.data) {
                    if (cat.value == this.cameleonService.CP) {
                        this.category = cat;
                        this.displayProduct = false;
                    } else if (cat.products) {
                        for (let p of cat.products) {
                            if (p.value == this.cameleonService.CP) {
                                this.category = cat;
                                this.product = p.value;
                                this.displayProduct = false;
                            }
                        }
                    }
                }
            }
        }, (err) => {
            
        });
    }

    get nextFOPath():any[] {
        return [this.localize.translateRoute('/' + this.reference.toUpperCase() + '/' + this.product)];
    }

    get isReferenceValid():boolean {
        let regex = /^[a-zA-Z0-9]{1,6}$/;

        let isValid = regex.test(this.reference) === true

        return this.reference && this.reference.length > 0 && isValid;
    }
}
