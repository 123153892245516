import { environment } from '~/environments/environment';

export class WINPRO {

  _productPrice:string = "0";
  _productDiscount:string = "0";
  _rsPrice:string = "0";
  _rsDiscount:string = "0";
  _drawingPath:string;
  _quoteId:string;
  _generativeProcess:Array<any>;

  constructor() {

  }

  get productPrice():string {
    return this._productPrice;
  }
  set productPrice(value:string) {
    this._productPrice = value;
  }

  get productDiscount():string {
    return this._productDiscount;
  }
  set productDiscount(value:string) {
    this._productDiscount = value;
  }

  get rsPrice():string {
    return this._rsPrice;
  }
  set rsPrice(value:string) {
    this._rsPrice = value;
  }

  get rsDiscount():string {
    return this._rsDiscount;
  }
  set rsDiscount(value:string) {
    this._rsDiscount = value;
  }

  get drawingPath():string {
    return this._drawingPath.replace("http://bouv-dev02:8180", environment.CAMELEON_SERVER);
  }
  set drawingPath(value:string) {
    this._drawingPath = value;
  }

  get quoteId():string {
    return this._quoteId;
  }
  set quoteId(value:string) {
    this._quoteId = value;
  }

  get generativeProcess():Array<any> {
    return this._generativeProcess;
  }
  set generativeProcess(value:Array<any>) {
    this._generativeProcess = value;
  }

  get totalPrice():number {
    let total:number = parseFloat(this.productPrice) * (100 - parseFloat(this.productDiscount)) / 100;
    if (parseFloat(this.rsPrice) > 0) {
      total += parseFloat(this.rsPrice) * (100 - parseFloat(this.rsDiscount)) / 100;
    }
    return total;
  }

  get totalPriceWithoutDiscount():number {
    let total:number = parseFloat(this.productPrice);
    if (parseFloat(this.rsPrice) > 0) {
      total += parseFloat(this.rsPrice);
    }
    return total;
  }

}
