<div #container *ngIf="cameleonService.state == 2">
  <div *ngFor="let group of groups" class="questions-group">
    <div class="questions-group__content">
      <ng-container *ngFor="let fp of group">
        <div *ngIf="fp.visible" [ngClass]="{'question':true, 'question--wait': !fp.failed && !fp.completed, 'question--failed': fp.failed, 'question--passed': fp.completed}">
          <label class="question__label">{{ fp.description }}</label>
          <ng-container *ngIf="fp.type == FPTypes.LIST">
            <simple-select [fp]="fp" (onChange)="setValue($event)"></simple-select>
          </ng-container>
          <ng-container *ngIf="fp.type == FPTypes.TEXT">
            <textinput [fp]="fp" (onChange)="setValue($event)"></textinput>
          </ng-container>
          <ng-container *ngIf="fp.type == FPTypes.IMAGES">
            <image-select-group [fp]="fp" (onChange)="setValue($event)"></image-select-group>
          </ng-container>
          <ng-container *ngIf="fp.type == FPTypes.CHECKBOXES">
            <checkbox-group [fp]="fp" (onChange)="setValue($event)"></checkbox-group>
          </ng-container>
          <ng-container *ngIf="fp.type == FPTypes.RADIOS">
            <radio-group [fp]="fp" (onChange)="setValue($event)"></radio-group>
          </ng-container>

          <span *ngIf="fp.min || fp.max" class="question__notice">
            <div *ngIf="fp.min">Mini {{ fp.min }}</div>
            <div *ngIf="fp.max">Maxi {{ fp.max }}</div>
          </span>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="questions-group" *ngIf="formCompleted()">
    <div class="questions-group__content questions-group__next">
      <a [routerLink]="nextFOPath" class="question button button--next">Etape suivante</a>
    </div>
  </div>
</div>

<div [ngClass]="{'messages':true, 'messages--opened': displayMessage}" *ngIf="messages.length > 0">
  <div class="messages__icon" (click)="displayMessage = !displayMessage">
    <i class="icon-plus-circle" *ngIf="!displayMessage"></i>
    <i class="icon-minus-circle" *ngIf="displayMessage"></i>
  </div>

  <div class="messages__container">
    <div class="messages__header">
      <i class="icon-exclamation-mark"></i> <span>Informations importantes pour votre configuration</span>
    </div>

    <div class="messages__content">
      <ul>
        <li *ngFor="let message of messages">{{ message }}</li>
      </ul>
    </div>
  </div>
</div>
