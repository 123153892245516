import { environment } from '~/environments/environment';
var WINPRO = /** @class */ (function () {
    function WINPRO() {
        this._productPrice = "0";
        this._productDiscount = "0";
        this._rsPrice = "0";
        this._rsDiscount = "0";
    }
    Object.defineProperty(WINPRO.prototype, "productPrice", {
        get: function () {
            return this._productPrice;
        },
        set: function (value) {
            this._productPrice = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WINPRO.prototype, "productDiscount", {
        get: function () {
            return this._productDiscount;
        },
        set: function (value) {
            this._productDiscount = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WINPRO.prototype, "rsPrice", {
        get: function () {
            return this._rsPrice;
        },
        set: function (value) {
            this._rsPrice = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WINPRO.prototype, "rsDiscount", {
        get: function () {
            return this._rsDiscount;
        },
        set: function (value) {
            this._rsDiscount = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WINPRO.prototype, "drawingPath", {
        get: function () {
            return this._drawingPath.replace("http://bouv-dev02:8180", environment.CAMELEON_SERVER);
        },
        set: function (value) {
            this._drawingPath = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WINPRO.prototype, "quoteId", {
        get: function () {
            return this._quoteId;
        },
        set: function (value) {
            this._quoteId = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WINPRO.prototype, "generativeProcess", {
        get: function () {
            return this._generativeProcess;
        },
        set: function (value) {
            this._generativeProcess = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WINPRO.prototype, "totalPrice", {
        get: function () {
            var total = parseFloat(this.productPrice) * (100 - parseFloat(this.productDiscount)) / 100;
            if (parseFloat(this.rsPrice) > 0) {
                total += parseFloat(this.rsPrice) * (100 - parseFloat(this.rsDiscount)) / 100;
            }
            return total;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WINPRO.prototype, "totalPriceWithoutDiscount", {
        get: function () {
            var total = parseFloat(this.productPrice);
            if (parseFloat(this.rsPrice) > 0) {
                total += parseFloat(this.rsPrice);
            }
            return total;
        },
        enumerable: true,
        configurable: true
    });
    return WINPRO;
}());
export { WINPRO };
