/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "@angular/common";
import * as i3 from "./checkbox-group.component";
import * as i4 from "../../cameleon/cameleon.service";
var styles_CheckboxGroupComponent = [];
var RenderType_CheckboxGroupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CheckboxGroupComponent, data: {} });
export { RenderType_CheckboxGroupComponent as RenderType_CheckboxGroupComponent };
function View_CheckboxGroupComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "checkbox-group__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 10, "div", [["class", "checkbox"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "input", [["type", "checkbox"]], [[8, "id", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 3).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.value[_v.context.$implicit.id].checked = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 16384, null, 0, i1.CheckboxControlValueAccessor, [i0.Renderer2, i0.ElementRef], null, null), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i1.CheckboxControlValueAccessor]), i0.ɵdid(5, 671744, null, 0, i1.NgModel, [[8, null], [8, null], [8, null], [6, i1.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i1.NgControl, null, [i1.NgModel]), i0.ɵdid(7, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null), (_l()(), i0.ɵeld(8, 0, null, null, 3, "label", [], [[8, "htmlFor", 0]], null, null, null, null)), i0.ɵdid(9, 278528, null, 0, i2.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(10, { "checkbox__label": 0, "checkbox__label--error": 1 }), (_l()(), i0.ɵted(11, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_8 = _v.context.$implicit.id; var currVal_9 = _co.value[_v.context.$implicit.id].checked; _ck(_v, 5, 0, currVal_8, currVal_9); var currVal_11 = _ck(_v, 10, 0, true, !_v.context.$implicit.authorized); _ck(_v, 9, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.fp.id + "/") + _v.context.$implicit.id); var currVal_1 = i0.ɵnov(_v, 7).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 7).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 7).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 7).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 7).ngClassValid; var currVal_6 = i0.ɵnov(_v, 7).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 7).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_10 = ((_co.fp.id + "/") + _v.context.$implicit.id); _ck(_v, 8, 0, currVal_10); var currVal_12 = _co.cameleonService.getBVDescription(_v.context.$implicit); _ck(_v, 11, 0, currVal_12); }); }
export function View_CheckboxGroupComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_CheckboxGroupComponent_1)), i0.ɵdid(1, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "button", [["class", "button checkbox-group__submit"], ["name", "button"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["VALIDER"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fp.bvs; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.ready; _ck(_v, 2, 0, currVal_1); }); }
export function View_CheckboxGroupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "checkbox-group", [["class", "checkbox-group"]], null, null, null, View_CheckboxGroupComponent_0, RenderType_CheckboxGroupComponent)), i0.ɵdid(1, 245760, null, 0, i3.CheckboxGroupComponent, [i4.CameleonService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CheckboxGroupComponentNgFactory = i0.ɵccf("checkbox-group", i3.CheckboxGroupComponent, View_CheckboxGroupComponent_Host_0, { fp: "fp" }, { onChange: "onChange" }, []);
export { CheckboxGroupComponentNgFactory as CheckboxGroupComponentNgFactory };
