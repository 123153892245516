<div class="setup">
  <div class="heading">
    <div class="heading__title">Informations</div>
  </div>

  
  <!-- QUESTIONS GROUP -->
  <div class="questions-group" *ngIf="displayRef">
    <div class="questions-group__content">
      <!-- QUESTION -->
      <div [ngClass]="{'question':true, 'question--wait': !reference || reference.length == 0, 'question--failed': reference && reference.length > 0 && !isReferenceValid, 'question--passed': isReferenceValid}">
        <label class="question__label">Indiquez votre repère*</label>
        <div class="textinput">
          <div class="textinput__input">
            <input type="text" name="" value="" required [(ngModel)]="reference" [pattern]="refPattern" style="text-transform: uppercase;">  
          </div>
        </div>
        <span class="question__notice">6 caractères alphanumériques maximum, pas de caractères spéciaux ni d'espace.</span>
        
      </div>
      <!-- QUESTION -->
    </div>
  </div>
  <!-- QUESTIONS GROUP -->

  <!-- QUESTIONS GROUP -->
  <div class="questions-group" *ngIf="displayProduct">
    <div class="questions-group__content">
      <!-- QUESTION -->
      <div [ngClass]="{'question':true, 'question--wait': !product, 'question--failed': !product, 'question--passed': product}">
        <label class="question__label">Choisissez votre menuiserie*</label>
        <div class="select__input">
          <select id="select-category" [(ngModel)]="category">
            <option *ngFor="let c of data" [ngValue]="c">{{c.label}}</option>
          </select>
        </div>
      </div>
      <!-- QUESTION -->

      <!-- QUESTION -->
      <div class="question" *ngIf="category?.products && category?.products.length > 0">
        <label class="question__label">&nbsp;</label>
        <div class="select__input">
          <select id="select-product" [(ngModel)]="product">
            <option *ngFor="let c of category.products" [ngValue]="c.value">{{c.label}}</option>
          </select>
        </div>
      </div>
      
      <!-- QUESTION -->
    </div>
  </div>
  <!-- QUESTIONS GROUP -->

  <!-- QUESTIONS GROUP -->
  <div class="questions-group" *ngIf="isReferenceValid">
    <div class="questions-group__content questions-group__next">
      <a class="question button button--next" [routerLink]="nextFOPath">Etape suivante</a>
    </div>
  </div>
</div>

<app-modal id="modal-message" width="640" #mm>
	<p class="_title-2" translate>Informations</p>
  <p *ngFor="let message of mm.data?.messages" [innerHtml]="'• '+(message | translate)"></p>
</app-modal>

<div [ngClass]="{'messages':true, 'messages--opened': displayMessage}" *ngIf="messages.length > 0">
  <div class="messages__icon" (click)="displayMessage = !displayMessage">
    <i class="icon-plus-circle" *ngIf="!displayMessage"></i>
    <i class="icon-minus-circle" *ngIf="displayMessage"></i>
  </div>

  <div class="messages__container">
    <div class="messages__header">
      <i class="icon-exclamation-mark"></i> <span>Informations importantes pour votre configuration</span>
    </div>

    <div class="messages__content">
      <ul>
        <li *ngFor="let message of messages">{{ message }}</li>
      </ul>
    </div>
  </div>
</div>
