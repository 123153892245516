export const GetCPStructureParameters = {
  defaults: {
    "parameters": [
      {
        "@name": "cpes",
        "list": [
        ]
      }
    ]
  }
}
