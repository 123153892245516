import { Inject, Injectable } from '@angular/core';

@Injectable()
export class ToolsService {

  /**
	 * Constructor
	 */
  constructor(

  ) {

  }
}
