<div class="checkbox-group__item" *ngFor="let bv of fp.bvs">
  <div class="checkbox">
    <input type="checkbox" [name]="bv.id" [id]="fp.id+'/'+bv.id" [(ngModel)]="value[bv.id].checked">
    <label [ngClass]="{'checkbox__label': true, 'checkbox__label--error': !bv.authorized}" [for]="fp.id+'/'+bv.id">
      {{ cameleonService.getBVDescription(bv) }}
    </label>
  </div>
</div>

<button class="button checkbox-group__submit" type="button" name="button" (click)="submit()" [disabled]="!ready">VALIDER</button>
