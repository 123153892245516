export const GetFPStructureParameters = {
  defaults: {
    "parameters": [
        {
            "@name": "cpeFormProperties",
            "list": [
                
            ]
        }
    ]
  }
}
