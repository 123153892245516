import { BV } from './BV.vo';
export var FPTypes;
(function (FPTypes) {
    FPTypes[FPTypes["LIST"] = 1] = "LIST";
    FPTypes[FPTypes["TEXT"] = 2] = "TEXT";
    FPTypes[FPTypes["RADIOS"] = 3] = "RADIOS";
    FPTypes[FPTypes["CHECKBOXES"] = 4] = "CHECKBOXES";
    FPTypes[FPTypes["IMAGES"] = 5] = "IMAGES";
})(FPTypes || (FPTypes = {}));
var FP = /** @class */ (function () {
    function FP(data) {
        if (data === void 0) { data = null; }
        if (data) {
            this.update(data);
        }
    }
    FP.prototype.update = function (data) {
        this._id = data['@cpe'];
        this._description = data['Description'];
        this._rawData = data;
        this._bvs = [];
        if (data['Domain']) {
            if (data['Domain']['IntervalsDomain']) {
                if (data['Domain']['IntervalsDomain']['Interval'] && data['Domain']['IntervalsDomain']['Interval'][0]['minimum'] != '-2147483648') {
                    this._min = data['Domain']['IntervalsDomain']['Interval'][0]['minimum'];
                }
                if (data['Domain']['IntervalsDomain']['Interval'] && data['Domain']['IntervalsDomain']['Interval'][0]['maximum'] != '2147483648') {
                    this._max = data['Domain']['IntervalsDomain']['Interval'][0]['maximum'];
                }
            }
            if (data['Domain']['ExtensionalDomain']) {
                for (var _i = 0, _a = data['Domain']['ExtensionalDomain']['Value']; _i < _a.length; _i++) {
                    var bv = _a[_i];
                    this._bvs.push(new BV(bv, data['Domain']['ExtensionalDomain']['@type']));
                }
            }
        }
        if (data['Layout']) {
            if (data['Layout']['@name'] == 'AutoCompleteComboBoxValueSelector') {
                this._type = FPTypes.LIST;
            }
            if (data['Layout']['@name'] == 'ValueListValueSelector_SimpleChoice'
                || data['Layout']['@name'] == 'ImageListValueSelector'
                || data['Layout']['@name'] == 'ValueListValueSelector_SimpleChoice_Himg') {
                this._type = FPTypes.IMAGES;
            }
            if (data['Layout']['@name'] == 'ValueListValueSelector_MultipleChoice') {
                this._type = FPTypes.CHECKBOXES;
            }
            if (data['Layout']['@name'] == 'ValueListValueSelector') {
                this._type = FPTypes.RADIOS;
            }
            if (data['Layout']['@name'] == 'TextFieldValueSelector'
                || data['Layout']['@name'] == 'TextFieldValueSelectorReset') {
                this._type = FPTypes.TEXT;
            }
        }
        if (this._rawData.SingleValuation && this._rawData.SingleValuation.Value && this._rawData.SingleValuation.Value.pkValue) {
            this._value = this._rawData.SingleValuation.Value.pkValue.replace(/\/descr\=(.*)/g, '');
        }
        else if (this._rawData.SingleValuation && this._rawData.SingleValuation.Value && this._rawData.SingleValuation.Value.integerValue) {
            this._value = this._rawData.SingleValuation.Value.integerValue;
        }
        else if (this._rawData.ValuationList && this._rawData.ValuationList.Value) {
            this._value = [];
            for (var _b = 0, _c = this._rawData.ValuationList.Value; _b < _c.length; _b++) {
                var bv = _c[_b];
                this._value.push(bv.pkValue);
            }
        }
        else {
            this._value = '';
        }
    };
    Object.defineProperty(FP.prototype, "id", {
        get: function () {
            return this._id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FP.prototype, "description", {
        get: function () {
            return this._description;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FP.prototype, "bvs", {
        get: function () {
            return this._bvs;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FP.prototype, "type", {
        get: function () {
            return this._type;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FP.prototype, "visible", {
        get: function () {
            return this._rawData.Status['@visible'] == "true" && this._rawData.Status['@exist'] == "true";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FP.prototype, "failed", {
        get: function () {
            return this._rawData.Status['@failed'] == "true";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FP.prototype, "completed", {
        get: function () {
            return this._rawData.Status['@completed'] == "true";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FP.prototype, "disabled", {
        get: function () {
            return this._rawData.Status['@updateable'] == "false";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FP.prototype, "mandatory", {
        get: function () {
            return this._rawData.Status['@mandatory'] == "true";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FP.prototype, "value", {
        get: function () {
            return this._value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FP.prototype, "min", {
        get: function () {
            return this._min;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FP.prototype, "max", {
        get: function () {
            return this._max;
        },
        enumerable: true,
        configurable: true
    });
    return FP;
}());
export { FP };
