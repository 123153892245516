import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalizeRouterService } from 'localize-router';
import { CameleonService } from '~/app/shared/cameleon/cameleon.service';
import { ApplicationData } from '~/app/shared/data/application.data';
import { environment } from '~/environments/environment';
var SetupComponent = /** @class */ (function () {
    /**
     * Constructor
     */
    function SetupComponent(cameleonService, applicationData, localize, http) {
        this.cameleonService = cameleonService;
        this.applicationData = applicationData;
        this.localize = localize;
        this.http = http;
        this.data = [];
        this.refPattern = /^[A-Z0-9]{1,6}$/;
        this.displayRef = true;
        this.displayProduct = false;
        this.displayMessage = false;
        this.messages = [];
    }
    Object.defineProperty(SetupComponent.prototype, "category", {
        get: function () { return this._category; },
        set: function (value) {
            this._category = value;
            if (this.category.products && this.category.products.length > 0) {
                this.product = this.category.products[0].value;
            }
            else {
                this.product = this.category.value;
            }
        },
        enumerable: true,
        configurable: true
    });
    ;
    Object.defineProperty(SetupComponent.prototype, "product", {
        get: function () { return this._product; },
        set: function (value) {
            this._product = value;
        },
        enumerable: true,
        configurable: true
    });
    ;
    Object.defineProperty(SetupComponent.prototype, "reference", {
        get: function () { return this._reference; },
        set: function (value) {
            this._reference = value;
            if (this.isReferenceValid) {
                this.messages = [];
                this.displayMessage = false;
            }
            else {
                this.messages = ["Votre repère doit comporter 6 caractères alphanumériques maximum, pas de caractères spéciaux ni d'espace."];
                this.displayMessage = true;
            }
        },
        enumerable: true,
        configurable: true
    });
    ;
    /**
     * On init
     */
    SetupComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.applicationData.reference == 'null') {
            this.applicationData.reference = null;
        }
        if (this.applicationData.reference) {
            this.reference = this.applicationData.reference;
            this.displayRef = false;
        }
        if (this.cameleonService.CP == 'null') {
            this.cameleonService.CP = '';
        }
        var httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Basic ' + btoa(environment.WS_USER + ':' + environment.WS_PWD),
            }),
            withCredentials: true
        };
        this.http.get(environment.WS_SERVER + environment.WS_API + '/Gamme/', httpOptions).subscribe(function (responseData) {
            _this.displayProduct = true;
            var res = responseData;
            res.ITEMS.sort(function (a, b) {
                return a['@Order'] - b['@Order'];
            });
            for (var _i = 0, _a = res.ITEMS; _i < _a.length; _i++) {
                var range = _a[_i];
                var data = {
                    "label": range['@Label'],
                    "value": range['@Id'],
                    "products": []
                };
                for (var _b = 0, _c = range.Item; _b < _c.length; _b++) {
                    var product = _c[_b];
                    data.products.push({
                        "label": product['@Label'],
                        "value": product['@Id'],
                    });
                }
                _this.data.push(data);
            }
            _this.category = _this.data[0];
            if (_this.cameleonService.CP && _this.cameleonService.CP.length > 0) {
                for (var _d = 0, _e = _this.data; _d < _e.length; _d++) {
                    var cat = _e[_d];
                    if (cat.value == _this.cameleonService.CP) {
                        _this.category = cat;
                        _this.displayProduct = false;
                    }
                    else if (cat.products) {
                        for (var _f = 0, _g = cat.products; _f < _g.length; _f++) {
                            var p = _g[_f];
                            if (p.value == _this.cameleonService.CP) {
                                _this.category = cat;
                                _this.product = p.value;
                                _this.displayProduct = false;
                            }
                        }
                    }
                }
            }
        }, function (err) {
        });
    };
    Object.defineProperty(SetupComponent.prototype, "nextFOPath", {
        get: function () {
            return [this.localize.translateRoute('/' + this.reference.toUpperCase() + '/' + this.product)];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SetupComponent.prototype, "isReferenceValid", {
        get: function () {
            var regex = /^[a-zA-Z0-9]{1,6}$/;
            var isValid = regex.test(this.reference) === true;
            return this.reference && this.reference.length > 0 && isValid;
        },
        enumerable: true,
        configurable: true
    });
    return SetupComponent;
}());
export { SetupComponent };
