import { Component, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { LocalizeRouterService } from 'localize-router';

import { CameleonService, CameleonStates } from '~/app/shared/cameleon/cameleon.service';
import { FPTypes } from '~/app/shared/cameleon/vo/FP.vo';
import { ApplicationState } from '~/app/shared/data/application.state';
import { ApplicationData } from '~/app/shared/data/application.data';
import { ModalService } from '~/app/shared/modal/modal.service';
import { FO } from '~/app/shared/cameleon/vo/FO.vo';

declare let imagesLoaded:any;

@Component({
  moduleId: module.id,
  selector: 'app-configuration',
  templateUrl: 'configuration.component.html',
  styleUrls: ['configuration.component.scss'],
  host: {
    'class': ''
  },
})

export class ConfigurationComponent {

  public displayMessage:boolean = false;
  public messages:any = [];
  public groups;

  private sub: any;
  protected FPTypes = FPTypes
  protected FO:string = "";
  protected currentFO:FO;

  private scrollTimeout:any;

  @ViewChild('container') container:ElementRef;

  /**
   * Constructor
   */
  constructor(
    public cameleonService:CameleonService,
    private applicationState:ApplicationState,
    private applicationData:ApplicationData,
    private modalService:ModalService,
    private route: ActivatedRoute,
    private router: Router,
    private localize: LocalizeRouterService
  ) {

  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.cameleonService.CP = params['cp'];
      this.FO = params['fo'];
      this.applicationData.reference = params['ref'];
    
      if (!this.applicationData.reference || !this.cameleonService.CP || this.applicationData.reference == 'null' || this.cameleonService.CP == 'null') {
        this.router.navigate([this.localize.translateRoute('/config')]);
      } else {
        setTimeout(() => {
            this.applicationState.loading = true;
    
            if (this.cameleonService.state != CameleonStates.READY) {
              if (this.applicationData.base64Data) {
                this.applicationData.autoNext = false;
                this.initCameleon(this.applicationData.base64Data);
              } else if (this.applicationData.itemId) {
                this.cameleonService.getDataFromExtranet(this.applicationData.itemId)
                  .then(
                    (result: any) => {
                      this.applicationData.autoNext = false;
                      if (result.base64) {
                        this.cameleonService.version = parseInt(result.version);
                        this.initCameleon(result.base64);
                      } else {
                        this.cameleonService.version = null;
                        this.initCameleon(result);
                      }
                    },
                    (error: any) => {
                      this.applicationState.loading = false;
                      this.modalService.open('modal-fatal-error', { title: 'UNE ERREUR EST SURVENUE', text: error.message.$ });
                    },
                  ).catch((e: Error) => {
                    console.log(e);
                    this.modalService.open('modal-fatal-error', { title: 'UNE ERREUR EST SURVENUE', text: 'Une erreur inconnue est survenue. Merci de fermer cette fenêtre et de recommencer une configuration.'});
                    this.applicationState.loading = false;
                  });
              } else {
                this.initCameleon();
              }
    
            } else {
              this.init();
            }
          }, 100);
      }

    });
  }

  /**
   * Init caméléon
   */
  initCameleon(data:any = null) {
    this.cameleonService.init(data)
      .then(
        (result: any) => {
          this.router.navigate([this.localize.translateRoute('/' + this.applicationData.reference + '/'+this.cameleonService.CP+'/'+this.cameleonService.breakdownTree[0].name)]);
          this.init();
        },
        (error: any) => {
          this.applicationState.loading = false;
          this.modalService.open('modal-fatal-error', { title: 'UNE ERREUR EST SURVENUE', text: error.message.$ });
        },
      ).catch((e: Error) => {
        console.log(e);
        this.modalService.open('modal-fatal-error', { title: 'UNE ERREUR EST SURVENUE', text: 'Une erreur inconnue est survenue. Merci de fermer cette fenêtre et de recommencer une configuration.'});
        this.applicationState.loading = false;
      });
  }

  /**
   * Init component
   */
  init() {
    if (this.FO) {
      this.cameleonService.keepAlive();
      this.currentFO = this.cameleonService.getFO(this.FO);
      this.applicationState.loading = false;

      this.update();
    } else {
      this.router.navigate([this.localize.translateRoute('/' + this.applicationData.reference + '/'+this.cameleonService.CP+'/'+this.cameleonService.breakdownTree[0].name)]);
    }
  }

  /**
   * Update component
   */
  update() {
    let targetId:string;
    if (document.activeElement && document.activeElement.id) {
      targetId = document.activeElement.id;
    }

    this.groups = [];
    let currentGroup = 0;
    this.groups[currentGroup] = [];
    let lastType;
    for (let fp of this.currentFO.fps) {
      if (fp.visible) {

        if ((fp.type == FPTypes.IMAGES || fp.type == FPTypes.CHECKBOXES) && lastType) {
          currentGroup++;
          this.groups[currentGroup] = [];
        } else if (lastType == FPTypes.IMAGES || lastType == FPTypes.CHECKBOXES) {
          currentGroup++;
          this.groups[currentGroup] = [];
        }

        this.groups[currentGroup].push(fp);

        lastType = fp.type;
      }
    }

    setTimeout(() => {
      if (this.currentFO.completed && (this.applicationData.autoNext && this.currentFO.name == this.cameleonService.breakdownTree[this.cameleonService.breakdownTree.length - 1].name) ) {
        this.applicationState.loading = false;
        this.router.navigate(this.nextFOPath);
      } else {
        if (targetId) {
          let target = document.getElementById(targetId);
          if (target) {
            target.focus();
          }
        }

        imagesLoaded(this.container.nativeElement, (instance) => {
          this.scrollToBottom();
          this.applicationState.loading = false;
        });
      }
    }, 100);
  }

  /**
	 * Scroll to bottom
	 */
  scrollToBottom() {

    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
      this.scrollTimeout = null;
    }
    this.scrollTimeout = setTimeout(() => {
      let q = document.getElementsByClassName('question--failed');
      if (q.length == 0) {
        q = document.getElementsByClassName('question--wait');
      }

      if (q.length > 0) {
        let target = <HTMLElement>q[0];

        var offset = 0;
        do {
          if (!isNaN(target.offsetTop)) {
            offset += target.offsetTop;
          }
        } while(target = <HTMLElement>target.offsetParent);

        try {
          window.scroll({ left: 0, top: offset, behavior: 'smooth' });
        } catch (e) {
          window.scrollTo(0, offset);
        }
      } else {
        document.documentElement.scrollTop = document.documentElement.scrollHeight;
      }
      this.scrollTimeout = null;
    }, 50);
  }

  /**
   * Set value
   */
  setValue(data:any) {
    this.cameleonService.keepAlive();
    this.applicationState.loading = true;
    this.cameleonService.setValue(data)
      .then(
        (result: any) => {
          this.cameleonService.getUserMessage(data).then(
            (result: any) => {
              let messages = [];
              for (let cpe in this.cameleonService.messages) {
                for (let m of this.cameleonService.messages[cpe]) {
                    //if (!m.read) {
                      messages.push(m.message);
                    //   m.read = true;
                    // }
                }
              }

              if (this.messages.length != messages.length) {
                this.displayMessage = true;
              }
              this.messages = messages;
            }
          );
          this.cameleonService.keepAlive();
          this.update();
        },
        (error: any) => {
          console.log(error);
          this.applicationState.loading = false;
          this.modalService.open('modal-error', { title: 'ERREUR', text: error.message.$ });
        },
      ).catch((e: Error) => {
        console.log(e);
        this.modalService.open('modal-error', { title: 'ERREUR', text: 'Unknown error.' });
        this.applicationState.loading = false;
      });
  }

  /**
   * Is form completed
   */
   formCompleted():boolean {
     return this.currentFO ? this.currentFO.completed : false;
   }

   get nextFOPath():any[] {
     for (let i = 0; i < this.cameleonService.breakdownTree.length; i++) {
       let fo = this.cameleonService.breakdownTree[i];

       if (i + 1 < this.cameleonService.breakdownTree.length) {
         if (fo.name == this.FO) {
           return [this.localize.translateRoute('/' + this.applicationData.reference + '/'+this.cameleonService.CP+'/'+this.cameleonService.breakdownTree[i + 1].name)];
         }
       }
     }

     return [this.localize.translateRoute('/' + this.applicationData.reference + '/'+this.cameleonService.CP+'/prix')];
   }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
