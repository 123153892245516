/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./home.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/router";
import * as i4 from "localize-router/src/localize-router.pipe";
import * as i5 from "localize-router/src/localize-router.service";
import * as i6 from "@ngx-translate/core";
import * as i7 from "../shared/modal/modal.component.ngfactory";
import * as i8 from "../shared/modal/modal.component";
import * as i9 from "../shared/modal/modal.service";
import * as i10 from "./home.component";
import * as i11 from "../shared/cameleon/cameleon.service";
import * as i12 from "../shared/data/application.data";
var styles_HomeComponent = [i0.styles];
var RenderType_HomeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HomeComponent, data: {} });
export { RenderType_HomeComponent as RenderType_HomeComponent };
function View_HomeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "li", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = ((_co.applicationData.autoNext = false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "navbar__item": 0, "navbar__item--disabled": 1, "navbar__item--passed": 2 }), i1.ɵdid(4, 16384, [[1, 4]], 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(5, 4), i1.ɵpid(131072, i4.LocalizeRouterPipe, [i5.LocalizeRouterService, i1.ChangeDetectorRef]), i1.ɵdid(7, 1720320, null, 2, i3.RouterLinkActive, [i3.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), i1.ɵpad(10, 1), (_l()(), i1.ɵeld(11, 0, null, null, 2, "a", [["class", "navbar__link"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "span", [["class", "navbar__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), (_l()(), i1.ɵeld(14, 0, null, null, 0, "li", [["class", "navbar__sep"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, true, !_v.context.$implicit.visible, _v.context.$implicit.completed); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 6).transform(_ck(_v, 5, 0, "/", _co.applicationData.reference, _co.cameleonService.CP, _v.context.$implicit.name))); _ck(_v, 4, 0, currVal_1); var currVal_2 = _ck(_v, 10, 0, "navbar__item--current"); _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var currVal_3 = _v.context.$implicit.description; _ck(_v, 13, 0, currVal_3); }); }
function View_HomeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "ul", [["class", "navbar"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomeComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 12, "li", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = ((_co.applicationData.autoNext = false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(5, { "navbar__item": 0, "navbar__item--disabled": 1 }), i1.ɵdid(6, 16384, [[3, 4]], 0, i3.RouterLink, [i3.Router, i3.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(7, 4), i1.ɵpid(131072, i4.LocalizeRouterPipe, [i5.LocalizeRouterService, i1.ChangeDetectorRef]), i1.ɵdid(9, 1720320, null, 2, i3.RouterLinkActive, [i3.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 3, { links: 1 }), i1.ɵqud(603979776, 4, { linksWithHrefs: 1 }), i1.ɵpad(12, 1), (_l()(), i1.ɵeld(13, 0, null, null, 2, "a", [["class", "navbar__link"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "span", [["class", "navbar__text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Prix d\u2019achat"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cameleonService.breakdownTree; _ck(_v, 2, 0, currVal_0); var currVal_1 = _ck(_v, 5, 0, true, !_co.cameleonService.completed); _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 8).transform(_ck(_v, 7, 0, "/", _co.applicationData.reference, _co.cameleonService.CP, "prix"))); _ck(_v, 6, 0, currVal_2); var currVal_3 = _ck(_v, 12, 0, "navbar__item--current"); _ck(_v, 9, 0, currVal_3); }, null); }
function View_HomeComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = ("\u2022 " + i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform(_v.context.$implicit))); _ck(_v, 0, 0, currVal_0); }); }
export function View_HomeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "home"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "heading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "heading__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["Rep\u00E8re : ", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HomeComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(8, 212992, null, 0, i3.RouterOutlet, [i3.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 6, "app-modal", [["id", "modal-message"], ["width", "640"]], [[40, "@visibilityChanged", 0]], [["component", "@visibilityChanged.start"], ["component", "@visibilityChanged.done"]], function (_v, en, $event) { var ad = true; if (("component:@visibilityChanged.start" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).animationStarted($event) !== false);
        ad = (pd_0 && ad);
    } if (("component:@visibilityChanged.done" === en)) {
        var pd_1 = (i1.ɵnov(_v, 10).animationEnded($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i7.View_ModalComponent_0, i7.RenderType_ModalComponent)), i1.ɵdid(10, 245760, [["mm", 4]], 0, i8.ModalComponent, [i9.ModalService, i1.ElementRef], { id: [0, "id"], width: [1, "width"] }, null), (_l()(), i1.ɵeld(11, 0, null, 0, 2, "p", [["class", "_title-2"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(12, 8536064, null, 0, i6.TranslateDirective, [i6.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Informations"])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_HomeComponent_3)), i1.ɵdid(15, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.cameleonService.state == 2); _ck(_v, 5, 0, currVal_1); _ck(_v, 8, 0); var currVal_3 = "modal-message"; var currVal_4 = "640"; _ck(_v, 10, 0, currVal_3, currVal_4); var currVal_5 = ""; _ck(_v, 12, 0, currVal_5); var currVal_6 = ((i1.ɵnov(_v, 10).data == null) ? null : i1.ɵnov(_v, 10).data.messages); _ck(_v, 15, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.applicationData.reference; _ck(_v, 3, 0, currVal_0); var currVal_2 = i1.ɵnov(_v, 10).visiblityState; _ck(_v, 9, 0, currVal_2); }); }
export function View_HomeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-home", [["class", ""]], null, null, null, View_HomeComponent_0, RenderType_HomeComponent)), i1.ɵdid(1, 114688, null, 0, i10.HomeComponent, [i11.CameleonService, i12.ApplicationData], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomeComponentNgFactory = i1.ɵccf("app-home", i10.HomeComponent, View_HomeComponent_Host_0, {}, {}, []);
export { HomeComponentNgFactory as HomeComponentNgFactory };
