export const GetFOStructureParameters = {
  defaults: {
    "parameters": [
        {
            "@name": "cpeForms",
            "list": [
                
            ]
        }
    ]
  }
}
