import { Component } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'app-components-library',
  templateUrl: 'components-library.component.html',
  styleUrls: ['components-library.component.scss'],
  host: {
    'class': ''
  },
})

export class ComponentsLibraryComponent {

  /**
   * Constructor
   */
  constructor() {

  }

  /**
   * On init
   */
  ngOnInit() {

  }
}
