import { Inject, Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { environment } from '~/environments/environment';
import { ContextTypes } from '~/environments/environment.types';

declare let ga:any;

@Injectable()
export class AnalyticsService {

  private analyticsID:string;

  /**
	 * Constructor
	 */
  constructor(private router: Router) {
  }

  /**
	 * Init
	 */
  public init(id:string):void {
    this.analyticsID = id;

    if (environment.CONTEXT == ContextTypes.APP) {
      ga.startTrackerWithId(this.analyticsID, () => {
        ga.trackView(this.router.url);
        this.router.events.subscribe(event => {
          if (event instanceof NavigationEnd) {
            ga.trackView((event as NavigationEnd).urlAfterRedirects);
          }
        });
      });
    } else {
      // tslint:disable
      (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*<any>new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
      ga('create', this.analyticsID, 'auto');
      // tslint:enable

      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd && ga) {
          ga('set', 'page', event.urlAfterRedirects);
          ga('send', 'pageview');
        }
      });
    }
  }
}
