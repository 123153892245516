import { BV } from './BV.vo';

export enum FPTypes {
	LIST = 1,
	TEXT = 2,
	RADIOS = 3,
	CHECKBOXES = 4,
	IMAGES = 5,
}


export class FP {

  _id:string;
  _type:FPTypes;
  _description:string;
  _rawData:any;
  _value:any;
  _min:string;
  _max:string;
  _bvs:BV[];

  constructor(data:any = null) {
    if (data) {
      this.update(data);
    }
  }

  public update(data:any) {
    this._id = data['@cpe'];
    this._description = data['Description'];
    this._rawData = data;

    this._bvs = [];
    if (data['Domain']) {
			if (data['Domain']['IntervalsDomain']) {
				if (data['Domain']['IntervalsDomain']['Interval'] && data['Domain']['IntervalsDomain']['Interval'][0]['minimum'] != '-2147483648') {
					this._min = data['Domain']['IntervalsDomain']['Interval'][0]['minimum'];
				}

				if (data['Domain']['IntervalsDomain']['Interval'] && data['Domain']['IntervalsDomain']['Interval'][0]['maximum'] != '2147483648') {
					this._max = data['Domain']['IntervalsDomain']['Interval'][0]['maximum'];
				}

			}

			if (data['Domain']['ExtensionalDomain']) {
				for (let bv of data['Domain']['ExtensionalDomain']['Value']) {
	        this._bvs.push(new BV(bv, data['Domain']['ExtensionalDomain']['@type']))
	      }
	    }
		}

		if (data['Layout']) {

			if (
				data['Layout']['@name'] == 'AutoCompleteComboBoxValueSelector'
			) {
	      this._type = FPTypes.LIST;
	    }

			if (
				data['Layout']['@name'] == 'ValueListValueSelector_SimpleChoice'
				|| data['Layout']['@name'] == 'ImageListValueSelector'
				|| data['Layout']['@name'] == 'ValueListValueSelector_SimpleChoice_Himg'
			) {
				this._type = FPTypes.IMAGES;
	    }

			if (
				data['Layout']['@name'] == 'ValueListValueSelector_MultipleChoice'
			) {
				this._type = FPTypes.CHECKBOXES;
	    }

			if (
				data['Layout']['@name'] == 'ValueListValueSelector'
			) {
				this._type = FPTypes.RADIOS;
	    }

			if (
				data['Layout']['@name'] == 'TextFieldValueSelector'
				|| data['Layout']['@name'] == 'TextFieldValueSelectorReset'
			) {
	      this._type = FPTypes.TEXT;
	    }
		}

		if (this._rawData.SingleValuation && this._rawData.SingleValuation.Value && this._rawData.SingleValuation.Value.pkValue) {
    	this._value = this._rawData.SingleValuation.Value.pkValue.replace(/\/descr\=(.*)/g, '');
		} else if (this._rawData.SingleValuation && this._rawData.SingleValuation.Value && this._rawData.SingleValuation.Value.integerValue) {
			this._value = this._rawData.SingleValuation.Value.integerValue;
		} else if (this._rawData.ValuationList && this._rawData.ValuationList.Value) {
			this._value = [];

			for (let bv of this._rawData.ValuationList.Value) {
				this._value.push(bv.pkValue);
			}
		} else {
			this._value =  '';
		}
  }

  get id():string {
    return this._id;
  }

  get description():string {
    return this._description;
  }

  get bvs():BV[] {
    return this._bvs;
  }

  get type():FPTypes {
    return this._type;
  }

  get visible():boolean {
    return this._rawData.Status['@visible'] == "true" && this._rawData.Status['@exist'] == "true";
  }

	get failed():boolean {
    return this._rawData.Status['@failed'] == "true";
  }

	get completed():boolean {
    return this._rawData.Status['@completed'] == "true";
  }

	get disabled():boolean {
    return this._rawData.Status['@updateable'] == "false";
  }

	get mandatory():boolean {
    return this._rawData.Status['@mandatory'] == "true";
  }

	get value():any {
		return this._value;
  }

	get min():string {
		return this._min;
  }
	get max():string {
		return this._max;
  }
}
