import { FP } from './FP.vo';

export class FO {

  _id:string;
  _name:string;
  _description:string;
  _rawData:any;
  _fps:FP[];

  constructor(data:any = null) {
    if (data) {
      this._id = data['@cpe'];
      this._name = data['@name'];
      this._description = data['Description'];
      this._fps = [];
      for (let fp of data['Children']) {
        this._fps.push(new FP(fp['FormPropertyChild']))
      }

      this.update(data);
    }
  }

  public update(data:any) {
    this._rawData = data;
  }

  get id():string {
    return this._id;
  }

  get name():string {
    return this._name;
  }

  get description():string {
    return this._description;
  }

  get fps():FP[] {
    return this._fps;
  }

  get visible():boolean {
    return this._rawData.Status['@visible'] == "true" && this._rawData.Status['@exist'] == "true";
  }

	get completed():boolean {
    return this._rawData.Status['@completed'] == "true";
  }
}
