import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';

import { TranslateService } from '@ngx-translate/core';

import { environment } from '~/environments/environment';
import { ContextTypes } from '~/environments/environment.types';

import { ApplicationStates } from './application.state';

declare let device:any;

@Injectable()
export class ApplicationData {

  public dataState: any = null;
  public reference: any = null;
  public projectId: any = null;
  public clientCode: any = null;
  public adherentCode: any = null;
  public cooperativeCode: any = null;
  public itemId: any = null;
  public autoNext:boolean = false;
  public base64Data:boolean = false;

  /**
	 * Constructor
	 */
  constructor(private http: HttpClient, private translate: TranslateService) {
    this.dataState = ApplicationStates.LOADING;
  }
}
