<div>

  <div class="components-library">

    <!-- --------------------------------------------------------------------- -->
    <div class="component-name">Heading</div>
    <div class="component">

      <!-- HEADING -->
      <div class="heading">
        <div class="heading__title">Repère : Salon</div>
        <div class="heading__content">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
        </div>
      </div>
      <!-- HEADING -->

    </div>
    <!-- --------------------------------------------------------------------- -->

    <!-- --------------------------------------------------------------------- -->
    <div class="component-name">Navbar</div>
    <div class="component">

    <!-- HEADING -->
    <ul class="navbar">
      <li class="navbar__item navbar__item--passed">
        <a href="#" class="navbar__link">Généralités</a>
      </li>
      <li class="navbar__item navbar__item--current">
        <a href="#" class="navbar__link">Dimensions</a>
      </li>
      <li class="navbar__item">
        <a href="#" class="navbar__link">Vitrage</a>
      </li>
      <li class="navbar__item">
        <a href="#" class="navbar__link">Volet roulant</a>
      </li>
      <li class="navbar__item">
        <a href="#" class="navbar__link">Options</a>
      </li>
      <li class="navbar__item">
        <a href="#" class="navbar__link">Prix d’achat</a>
      </li>

    </ul>
      <!-- HEADING -->

    </div>
    <!-- --------------------------------------------------------------------- -->

    <!-- --------------------------------------------------------------------- -->
    <div class="component-name">Simple input</div>
    <div class="component">

      <!-- SINGLE INPUT -->
      <div class="textinput">
        <div class="textinput__input">
          <input type="text" name="" value=""> mm
        </div>
      </div>
      <!-- SINGLE INPUT -->

    </div>
    <!-- --------------------------------------------------------------------- -->

    <!-- --------------------------------------------------------------------- -->
    <div class="component-name">Simple radio</div>
    <div class="component">

      <!-- SIMPLE RADIO -->
      <div class="radio">
        <input type="radio" name="radio1" id="radio-0-1" value="">
        <label class="radio__label" for="radio-0-1">Réponse</label>
      </div>
      <!-- SIMPLE RADIO -->

    </div>
    <!-- --------------------------------------------------------------------- -->

    <!-- --------------------------------------------------------------------- -->
    <div class="component-name">Simple checkbox</div>
    <div class="component">

      <!-- SIMPLE CHECKBOX -->
      <div class="checkbox">
        <input type="checkbox" name="checkbox1" id="checkbox-0-1" value="">
        <label class="checkbox__label" for="checkbox-0-1">Réponse</label>
      </div>
      <!-- SIMPLE CHECKBOX -->

    </div>
    <!-- --------------------------------------------------------------------- -->

    <!-- --------------------------------------------------------------------- -->
    <div class="component-name">Simple select</div>
    <div class="component">

      <!-- SINGLE SELECT -->
      <div class="select">
        <div class="select__input">
          <select id="select-sample">
            <option value="Coulissant">Coulissant</option>
            <option value="Porte d'entrée">Porte d'entrée</option>
            <option value="Fenêtre">Fenêtre</option>
            <option value="Volet roulant">Volet roulant</option>
          </select>
        </div>
      </div>
      <!-- SINGLE SELECT -->

    </div>
    <!-- --------------------------------------------------------------------- -->

    <!-- --------------------------------------------------------------------- -->
    <div class="component-name">Image select</div>
    <div class="component" style="display:inline-block; max-width:240px">

      <!-- IMAGE SELECT -->
      <div class="image-select">
        <label class="image-select__label" for="radio-1-1">
          <input type="radio" name="radio1" id="radio-1-1" value="">
          <div class="image-select__container">
            <img src="assets/images/sample-color.png" alt="" class="image-select__image">
            <div class="image-select__description">
              Chêne liège extérieur / blanc intérieur
            </div>
          </div>
        </label>
      </div>
      <!-- IMAGE SELECT -->

    </div>
    <!-- --------------------------------------------------------------------- -->

    <!-- --------------------------------------------------------------------- -->
    <div class="component-name">Image select</div>
    <div class="component" style="display:inline-block; max-width:240px">

      <!-- IMAGE SELECT -->
      <div class="image-select">
        <label class="image-select__label" for="radio-1-2">
          <input type="radio" name="radio1" id="radio-1-2" value="">
          <div class="image-select__container">
            <img src="https://via.placeholder.com/181x131" alt="" class="image-select__image">
            <div class="image-select__description">
              Chêne liège extérieur / blanc intérieur
            </div>
          </div>
        </label>
      </div>
      <!-- IMAGE SELECT -->

    </div>
    <!-- --------------------------------------------------------------------- -->

    <!-- --------------------------------------------------------------------- -->
    <div class="component-name">Questions</div>
    <div class="component">

      <!-- QUESTIONS GROUP -->
      <div class="questions-group">
        <div class="questions-group__content">
          <!-- QUESTION -->
          <div class="question">
            <label class="question__label">Question 1</label>
            <div class="select__input">
              <select id="select-sample">
                <option value="Réponse 1">Réponse 1</option>
                <option value="Réponse 2">Réponse 2</option>
                <option value="Réponse 3">Réponse 3</option>
                <option value="Réponse 4">Réponse 4 Réponse 4 Réponse 4 Réponse 4</option>
              </select>
            </div>
          </div>
          <!-- QUESTION -->

          <!-- QUESTION -->
          <div class="question">
            <label class="question__label">Question 2</label>
            <div class="select__input">
              <select id="select-sample">
                <option value="Réponse 1">Réponse 1</option>
                <option value="Réponse 2">Réponse 2</option>
                <option value="Réponse 3">Réponse 3</option>
                <option value="Réponse 4">Réponse 4</option>
              </select>
            </div>
            <span class="question__notice">Dépose du dormant existant et repose avec solution dépose totale exclusive Bouvet</span>
          </div>
          <!-- QUESTION -->

          <!-- QUESTION -->
          <div class="question">
            <label class="question__label">Question 3</label>
            <div class="select__input">
              <select id="select-sample">
                <option value="Réponse 1">Réponse 1</option>
                <option value="Réponse 2">Réponse 2</option>
                <option value="Réponse 3">Réponse 3</option>
                <option value="Réponse 4">Réponse 4 Réponse 4 Réponse 4 Réponse 4</option>
              </select>
            </div>
          </div>
          <!-- QUESTION -->
        </div>
      </div>
      <!-- QUESTIONS GROUP -->

      <!-- QUESTIONS GROUP -->
      <div class="questions-group">
        <div class="questions-group__content">
          <!-- QUESTION -->
          <div class="question">
            <label class="question__label">Question 4</label>
            <div class="select__input">
              <select id="select-sample">
                <option value="Réponse 1">Réponse 1</option>
                <option value="Réponse 2">Réponse 2</option>
                <option value="Réponse 3">Réponse 3</option>
                <option value="Réponse 4">Réponse 4 Réponse 4 Réponse 4 Réponse 4</option>
              </select>
            </div>
          </div>
          <!-- QUESTION -->

          <!-- QUESTION -->
          <div class="question">
            <label class="question__label">Question 5</label>
            <div class="select__input">
              <select id="select-sample">
                <option value="Réponse 1">Réponse 1</option>
                <option value="Réponse 2">Réponse 2</option>
                <option value="Réponse 3">Réponse 3</option>
                <option value="Réponse 4">Réponse 4</option>
              </select>
            </div>
          </div>
          <!-- QUESTION -->

          <!-- QUESTION -->
          <div class="question">
            <label class="question__label">Question 6</label>
            <div class="select__input">
              <select id="select-sample">
                <option value="Réponse 1">Réponse 1</option>
                <option value="Réponse 2">Réponse 2</option>
                <option value="Réponse 3">Réponse 3</option>
                <option value="Réponse 4">Réponse 4</option>
              </select>
            </div>
          </div>
          <!-- QUESTION -->
        </div>
      </div>
      <!-- QUESTIONS GROUP -->

      <!-- QUESTIONS GROUP -->
      <div class="questions-group">
        <div class="questions-group__content">
          <!-- QUESTION -->
          <div class="question">
            <label class="question__label">Question 7</label>

            <div class="image-select-group">
              <div class="image-select-group__item">
                <div class="image-select">
                  <label class="image-select__label" for="radio-2-1">
                    <input type="radio" name="radio2" id="radio-2-1" value="">
                    <div class="image-select__container">
                      <img src="assets/images/sample-color.png" alt="" class="image-select__image">
                      <div class="image-select__description">
                        Chêne liège extérieur / blanc intérieur
                      </div>
                    </div>
                  </label>
                </div>
              </div>

              <div class="image-select-group__item">
                <div class="image-select">
                  <label class="image-select__label" for="radio-2-2">
                    <input type="radio" name="radio2" id="radio-2-2" value="">
                    <div class="image-select__container">
                      <img src="assets/images/sample-color.png" alt="" class="image-select__image">
                      <div class="image-select__description">
                        Chêne liège extérieur / blanc intérieur
                      </div>
                    </div>
                  </label>
                </div>
              </div>

              <div class="image-select-group__item">
                <div class="image-select">
                  <label class="image-select__label" for="radio-2-3">
                    <input type="radio" name="radio2" id="radio-2-3" value="">
                    <div class="image-select__container">
                      <img src="assets/images/sample-color.png" alt="" class="image-select__image">
                      <div class="image-select__description">
                        Chêne liège extérieur / blanc intérieur
                      </div>
                    </div>
                  </label>
                </div>
              </div>

            </div>

          </div>
          <!-- QUESTION -->

        </div>
      </div>
      <!-- QUESTIONS GROUP -->

      <!-- QUESTIONS GROUP -->
      <div class="questions-group">
        <div class="questions-group__content">
          <!-- QUESTION -->
          <div class="question">
            <label class="question__label">Question 8</label>

            <div class="textinput">
              <div class="textinput__input">
                <input type="text" name="" value=""> mm
              </div>
            </div>

            <span class="question__notice">
              <div>Mini 825</div>
              <div>Maxi 2450</div>
            </span>
          </div>
          <!-- QUESTION -->

          <!-- QUESTION -->
          <div class="question">
            <label class="question__label">Question 9</label>

            <div class="radio-group">
              <div class="radio-group__item">
                <div class="radio">
                  <input type="radio" name="radio1" id="radio-3-1" value="">
                  <label class="radio__label" for="radio-3-1">Réponse 1</label>
                </div>
              </div>

              <div class="radio-group__item">
                <div class="radio">
                  <input type="radio" name="radio1" id="radio-3-2" value="">
                  <label class="radio__label" for="radio-3-2">Réponse 2</label>
                </div>
              </div>

              <div class="radio-group__item">
                <div class="radio">
                  <input type="radio" name="radio1" id="radio-3-3" value="">
                  <label class="radio__label" for="radio-3-3">Réponse 3</label>
                </div>
              </div>

              <div class="radio-group__item">
                <div class="radio">
                  <input type="radio" name="radio1" id="radio-3-4" value="">
                  <label class="radio__label" for="radio-3-4">Réponse 4</label>
                </div>
              </div>

          </div>
        </div>
        <!-- QUESTION -->

        <!-- QUESTION -->
        <div class="question">
          <label class="question__label">Question 10</label>

          <div class="checkbox-group">
            <div class="checkbox-group__item">
              <div class="checkbox">
                <input type="checkbox" name="checkbox1" id="checkbox-1-1" value="">
                <label class="checkbox__label" for="checkbox-1-1">Réponse 1</label>
              </div>
            </div>

            <div class="checkbox-group__item">
              <div class="checkbox">
                <input type="checkbox" name="checkbox1" id="checkbox-1-2" value="">
                <label class="checkbox__label" for="checkbox-1-2">Réponse 2</label>
              </div>
            </div>

            <div class="checkbox-group__item">
              <div class="checkbox">
                <input type="checkbox" name="checkbox1" id="checkbox-1-3" value="">
                <label class="checkbox__label" for="checkbox-1-3">Réponse 3</label>
              </div>
            </div>

        </div>
      </div>
      <!-- QUESTION -->

    </div>
    <!-- QUESTIONS GROUP -->

  </div>

</div>
