/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./shared/loader/loader.component.ngfactory";
import * as i5 from "./shared/loader/loader.component";
import * as i6 from "@angular/common";
import * as i7 from "./shared/modal/modal.component.ngfactory";
import * as i8 from "./shared/modal/modal.component";
import * as i9 from "./shared/modal/modal.service";
import * as i10 from "./app.component";
import * as i11 from "./shared/data/application.state";
import * as i12 from "./shared/data/application.data";
import * as i13 from "localize-router/src/localize-router.service";
import * as i14 from "./shared/services/analytics.service";
import * as i15 from "./shared/cameleon/cameleon.service";
import * as i16 from "@angular/common/http";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: { "animation": [{ type: 7, name: "appTransition", definitions: [{ type: 1, expr: "* => *", animation: [{ type: 11, selector: ":enter, :leave", animation: { type: 6, styles: { position: "fixed", width: "100%", height: "100%", left: 0, top: 0 }, offset: null }, options: { optional: true } }, { type: 11, selector: ":enter", animation: { type: 6, styles: { transform: "translateX(100%)" }, offset: null }, options: { optional: true } }, { type: 2, steps: [{ type: 11, selector: ":leave", animation: { type: 9, options: null }, options: { optional: true } }, { type: 3, steps: [{ type: 11, selector: ":leave", animation: [{ type: 6, styles: { transform: "translateX(0%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateX(-100%)" }, offset: null }, timings: "600ms cubic-bezier(.69,0,.23,1)" }], options: { optional: true } }, { type: 11, selector: ":enter", animation: [{ type: 6, styles: { transform: "translateX(100%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateX(0%)" }, offset: null }, timings: "600ms cubic-bezier(.69,0,.23,1)" }], options: { optional: true } }], options: null }, { type: 11, selector: ":enter", animation: { type: 9, options: null }, options: { optional: true } }], options: null }], options: null }], options: {} }] } });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "main", [], [[24, "@appTransition", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(2, 212992, [["o", 4]], 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getState(i1.ɵnov(_v, 2)); _ck(_v, 0, 0, currVal_0); }); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["class", "_title-2"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i3.TranslateDirective, [i3.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(2, null, ["", ""]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = ((i1.ɵnov(_v.parent, 3).data == null) ? null : i1.ɵnov(_v.parent, 3).data.title); _ck(_v, 2, 0, currVal_1); }); }
function View_AppComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform(((i1.ɵnov(_v.parent, 3).data == null) ? null : i1.ɵnov(_v.parent, 3).data.text))); _ck(_v, 0, 0, currVal_0); }); }
function View_AppComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["class", "_title-2"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i3.TranslateDirective, [i3.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(2, null, ["", ""]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = ((i1.ɵnov(_v.parent, 9).data == null) ? null : i1.ɵnov(_v.parent, 9).data.title); _ck(_v, 2, 0, currVal_1); }); }
function View_AppComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform(((i1.ɵnov(_v.parent, 9).data == null) ? null : i1.ɵnov(_v.parent, 9).data.text))); _ck(_v, 0, 0, currVal_0); }); }
function View_AppComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-loader", [], null, null, null, i4.View_LoaderComponent_0, i4.RenderType_LoaderComponent)), i1.ɵdid(1, 49152, null, 0, i5.LoaderComponent, [], { label: [0, "label"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-loader", [], null, null, null, i4.View_LoaderComponent_0, i4.RenderType_LoaderComponent)), i1.ɵdid(3, 49152, null, 0, i5.LoaderComponent, [], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.applicationState.loadingLabel; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 5, "app-modal", [["id", "modal-error"], ["width", "640"]], [[40, "@visibilityChanged", 0]], [["component", "@visibilityChanged.start"], ["component", "@visibilityChanged.done"]], function (_v, en, $event) { var ad = true; if (("component:@visibilityChanged.start" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).animationStarted($event) !== false);
        ad = (pd_0 && ad);
    } if (("component:@visibilityChanged.done" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).animationEnded($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i7.View_ModalComponent_0, i7.RenderType_ModalComponent)), i1.ɵdid(3, 245760, [["me", 4]], 0, i8.ModalComponent, [i9.ModalService, i1.ElementRef], { id: [0, "id"], width: [1, "width"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_AppComponent_2)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_AppComponent_3)), i1.ɵdid(7, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 5, "app-modal", [["blocking", "true"], ["id", "modal-fatal-error"], ["width", "640"]], [[40, "@visibilityChanged", 0]], [["component", "@visibilityChanged.start"], ["component", "@visibilityChanged.done"]], function (_v, en, $event) { var ad = true; if (("component:@visibilityChanged.start" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).animationStarted($event) !== false);
        ad = (pd_0 && ad);
    } if (("component:@visibilityChanged.done" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9).animationEnded($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i7.View_ModalComponent_0, i7.RenderType_ModalComponent)), i1.ɵdid(9, 245760, [["mfe", 4]], 0, i8.ModalComponent, [i9.ModalService, i1.ElementRef], { id: [0, "id"], width: [1, "width"], blocking: [2, "blocking"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_AppComponent_4)), i1.ɵdid(11, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_AppComponent_5)), i1.ɵdid(13, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_6)), i1.ɵdid(15, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.applicationState.state != _co.ApplicationStates.ERROR); _ck(_v, 1, 0, currVal_0); var currVal_2 = "modal-error"; var currVal_3 = "640"; _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_4 = ((i1.ɵnov(_v, 3).data == null) ? null : i1.ɵnov(_v, 3).data.title); _ck(_v, 5, 0, currVal_4); var currVal_5 = ((i1.ɵnov(_v, 3).data == null) ? null : i1.ɵnov(_v, 3).data.text); _ck(_v, 7, 0, currVal_5); var currVal_7 = "modal-fatal-error"; var currVal_8 = "640"; var currVal_9 = "true"; _ck(_v, 9, 0, currVal_7, currVal_8, currVal_9); var currVal_10 = ((i1.ɵnov(_v, 9).data == null) ? null : i1.ɵnov(_v, 9).data.title); _ck(_v, 11, 0, currVal_10); var currVal_11 = ((i1.ɵnov(_v, 9).data == null) ? null : i1.ɵnov(_v, 9).data.text); _ck(_v, 13, 0, currVal_11); var currVal_12 = _co.applicationState.loading; _ck(_v, 15, 0, currVal_12); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 3).visiblityState; _ck(_v, 2, 0, currVal_1); var currVal_6 = i1.ɵnov(_v, 9).visiblityState; _ck(_v, 8, 0, currVal_6); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, [["window", "unload"]], function (_v, en, $event) { var ad = true; if (("window:unload" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).unloadHandler($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i10.AppComponent, [i11.ApplicationState, i12.ApplicationData, i3.TranslateService, i13.LocalizeRouterService, i9.ModalService, i14.AnalyticsService, i15.CameleonService, i2.Router, i16.HttpClient], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i10.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
