import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LocalizeRouterModule, LocalizeRouterSettings, LocalizeParser } from 'localize-router';
import { LocalizeRouterHttpLoader } from 'localize-router-http-loader';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';

import { SetupComponent } from './setup/setup.component';
import { HomeComponent } from './home/home.component';
import { WinproComponent } from './winpro/winpro.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { ComponentsLibraryComponent } from './components-library/components-library.component';

import { AppCustomPreloader } from './app-routing.loader';
import { WinproGuard } from './app-routing.guards';

export function HttpLoaderFactory(translate: TranslateService, location: Location, settings: LocalizeRouterSettings, http: HttpClient) {
  return new LocalizeRouterHttpLoader(translate, location, settings, http);
}

const routes = [
  { path: 'components-library', component: ComponentsLibraryComponent},
  { path: 'config', component: SetupComponent},
  { path: '', component: HomeComponent, children: [
    { path: '',  component: ConfigurationComponent, pathMatch:'full'},
    { path: ':ref',  component: ConfigurationComponent, pathMatch:'full'},
    { path: ':ref/:cp/prix', component: WinproComponent, canActivate: [ WinproGuard ]},
    { path: ':ref/:cp', component: ConfigurationComponent},
    { path: ':ref/:cp/:fo', component: ConfigurationComponent},
  ]},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: AppCustomPreloader }),
    LocalizeRouterModule.forRoot(routes, {
      parser: {
        provide: LocalizeParser,
        useFactory: HttpLoaderFactory,
        deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient]
      }
    })
  ],
  providers:[ AppCustomPreloader ],
  exports: [ RouterModule, LocalizeRouterModule ]
})
export class AppRoutingModule {}
