import { Inject, Injectable } from '@angular/core';

@Injectable()
export class StartupService {

  /**
	 * Constructor
	 */
  constructor() {
  }

  /**
	 * Startup
	 */
  public startup() {
    return new Promise((resolve, reject) => {
      resolve(true);
    });
  }
}
