import { EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { CameleonService } from '~/app/shared/cameleon/cameleon.service';
var CheckboxGroupComponent = /** @class */ (function () {
    /**
       * Constructor
       */
    function CheckboxGroupComponent(cameleonService) {
        this.cameleonService = cameleonService;
        this._json = {
            "parameters": [
                {
                    "@name": "valuations",
                    "list": [
                        {
                            "pair": {
                                "left": {
                                    "cpe": ""
                                },
                                "right": {
                                    "ValuationList": {
                                        "@isCommentUserChoice": "false",
                                        "@isQtyUserChoice": "false",
                                        "@isValueUserChoice": "true",
                                        "Value": [],
                                        "Comment": null
                                    }
                                }
                            }
                        }
                    ]
                }
            ]
        };
        this._value = {};
        this.onChange = new EventEmitter();
    }
    Object.defineProperty(CheckboxGroupComponent.prototype, "fp", {
        get: function () { return this._fp; },
        set: function (data) {
            this._value = {};
            console.log(data.value);
            var _loop_1 = function (bv) {
                console.log(bv);
                this_1._value[bv.id] = {
                    checked: data.value && data.value.findIndex(function (val) { return val.includes(bv.id); }) > -1
                };
            };
            var this_1 = this;
            for (var _i = 0, _a = data.bvs; _i < _a.length; _i++) {
                var bv = _a[_i];
                _loop_1(bv);
            }
            this._fp = data;
            this._json.parameters[0].list[0].pair.left.cpe = this.fp.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CheckboxGroupComponent.prototype, "value", {
        get: function () { return this._value; },
        set: function (data) {
            this._value = data;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * On init
     */
    CheckboxGroupComponent.prototype.ngOnInit = function () {
    };
    Object.defineProperty(CheckboxGroupComponent.prototype, "ready", {
        /**
           * Ready
           */
        get: function () {
            for (var bv in this.value) {
                if (this.value[bv].checked) {
                    return true;
                }
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    /**
       * Submit
       */
    CheckboxGroupComponent.prototype.submit = function () {
        this._json.parameters[0].list[0].pair.right.ValuationList.Value = [];
        for (var bv in this.value) {
            if (this.value[bv].checked) {
                this._json.parameters[0].list[0].pair.right.ValuationList.Value.push({ "pkValue": bv });
            }
        }
        this.onChange.emit(this._json);
    };
    /**
    * On destroy
    */
    CheckboxGroupComponent.prototype.ngOnDestroy = function () {
    };
    return CheckboxGroupComponent;
}());
export { CheckboxGroupComponent };
