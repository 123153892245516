import { Component, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { LocalizeRouterService } from 'localize-router';

import { CameleonService, CameleonStates } from '~/app/shared/cameleon/cameleon.service';
import { ApplicationState } from '~/app/shared/data/application.state';
import { ApplicationData } from '~/app/shared/data/application.data';
import { ModalService } from '~/app/shared/modal/modal.service';

declare let imagesLoaded:any;

@Component({
  moduleId: module.id,
  selector: 'app-winpro',
  templateUrl: 'winpro.component.html',
  styleUrls: ['winpro.component.scss'],
  host: {
    'class': ''
  },
})

export class WinproComponent {

  public data:any;
  public error:any;

  @ViewChild('container') container:ElementRef;

  /**
   * Constructor
   */
  constructor(
    public cameleonService:CameleonService,
    private applicationState:ApplicationState,
    private applicationData:ApplicationData,
    private modalService:ModalService,
    private route: ActivatedRoute,
    private router: Router,
    private localize: LocalizeRouterService
  ) {

  }

  /**
   * Init
   */
  ngOnInit() {

    setTimeout(() => {
      //if (this.cameleonService.completed) {
        this.applicationState.loading = true;
        this.cameleonService.getResult()
          .then(
            (result: any) => {
              this.data = result;
              setTimeout(() => {
                imagesLoaded(this.container.nativeElement, (instance) => {
                  this.applicationState.loading = false;
                });
              }, 100);

              this.cameleonService.log({
                datas: '',
                status: '1',
                error: '',
                customerCode: this.cameleonService.CC,
                idCarpentry: this.applicationData.itemId ? this.applicationData.itemId : '',
                idProjet: this.applicationData.projectId
              });

            },
            (error: any) => {
              this.applicationState.loading = false;
              this.error = error.message.$;

              this.cameleonService.log({
                datas: null,
                status: '0',
                error: error.message.$,
                customerCode: this.cameleonService.CC,
                idCarpentry: this.applicationData.itemId ? this.applicationData.itemId : '',
                idProjet: this.applicationData.projectId
              });
              //this.modalService.open('modal-error', { title: 'UNE ERREUR EST SURVENUE', text: error.message.$ });
            }
          ).catch((e: Error) => {
            console.log(e);
            this.modalService.open('modal-fatal-error', { title: 'UNE ERREUR EST SURVENUE', text: 'Une erreur inconnue est survenue. Merci de fermer cette fenêtre et de recommencer une configuration.'});
            this.applicationState.loading = false;
          });
      //}
    }, 100);
  }

  /**
   * Save
   */
  // price:  this.applicationData.adherentCode ? this.data.totalPriceWithoutDiscount : this.data.totalPrice,
  save() {
    this.applicationState.loading = true;
    let data = {
      from: 'configurator',
      id: this.applicationData.projectId,
      price:  this.applicationData.adherentCode ? this.data.totalPriceWithoutDiscount : this.data.totalPriceWithoutDiscount,
      image: this.data.drawingPath,
      description: this.cameleonService.productDescription,
      configuration: null,
      itemId: this.applicationData.itemId,
      fileName: this.data.quoteId,
        summary: this.data.generativeProcess,
        carpentryPrice: this.data.productPrice,
        rsPrice: this.data.rsPrice
    }

    this.cameleonService.save()
      .then(
        (result: any) => {
          data.configuration = result;
          if (parent) {
            parent.postMessage(JSON.stringify(data),"*");
          } else {
            postMessage(JSON.stringify(data),"*");
          }
        },
        (error: any) => {
          console.log(error);
          this.applicationState.loading = false;
          this.modalService.open('modal-error', { title: 'UNE ERREUR EST SURVENUE', text: error.message.$ });
        }
      ).catch((e: Error) => {
        console.log(e);
        this.modalService.open('modal-error', { title: 'UNE ERREUR EST SURVENUE', text: 'Une erreur inconnue est survenue. Merci de réessayer.'});
        this.applicationState.loading = false;
      });
  }

  /**
   * Destroy
   */
  ngOnDestroy() {

  }
}
