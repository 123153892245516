import { Inject, Injectable } from '@angular/core';

import { environment } from '~/environments/environment';
import { ContextTypes } from '~/environments/environment.types';

export enum ApplicationStates {
  LOADING = 1,
  READY = 2,
  ERROR = 3,
}

@Injectable()
export class ApplicationState {

  public state:any = null;
  private _loading = 0;
  public loadingLabel = '';

  /**
   * loading
   */
  set loading(value:boolean) {
    this._loading = Math.max(0, value ? this._loading + 1 : this._loading - 1);

    if (!this.loading) {
      this.loadingLabel = '';
    }
  }

  get loading():boolean {
    return this._loading > 0;
  }
}
