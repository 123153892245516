import { Component, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd, NavigationStart, NavigationEnd } from '@angular/router';

import { LocalizeRouterService } from 'localize-router';

import { environment } from '~/environments/environment';
import { ContextTypes } from '~/environments/environment.types';

import { ApplicationState, ApplicationStates } from '~/app/shared/data/application.state';
import { ApplicationData } from '~/app/shared/data/application.data';
import { ModalService } from '~/app/shared/modal/modal.service';
import { CameleonService } from '~/app/shared/cameleon/cameleon.service';
import { AnalyticsService } from '~/app/shared/services/analytics.service';

import { appTransition } from './app.animations';
import { HttpClient, HttpHeaders } from '@angular/common/http';

declare let FastClick:any;
declare const window: any;

@Component({
    moduleId: module.id,
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    animations: [ appTransition ],
})
export class AppComponent {

    public environment = environment;
    public ContextTypes = ContextTypes;
    public ApplicationStates = ApplicationStates;

    private loaderTimeout:number;

    /**
	 * Constructor
	 */
    constructor(public applicationState:ApplicationState, private applicationData:ApplicationData,
                private translate:TranslateService, private localize:LocalizeRouterService,
                private modalService: ModalService, private analyticsService:AnalyticsService,
                private cameleonService:CameleonService, private router:Router, private http: HttpClient) {

        this.translate.setDefaultLang('fr');

        this.init();

        this.router.events.subscribe(event => {
        if (event instanceof RouteConfigLoadStart) {
            this.loaderTimeout = window.setTimeout(() => { this.applicationState.loading = true; this.loaderTimeout = null; }, 300);
        } else if (event instanceof RouteConfigLoadEnd) {
            this.applicationState.loading = false;
            if (this.loaderTimeout) {
                clearTimeout(this.loaderTimeout);
                this.loaderTimeout = null;
            }
        }
        });
    }

    /**
     * Init App
     */
    public init():void {
        this.analyticsService.init(environment.GOOGLE_ANALYTICS_ID);
        FastClick.attach(document.body);

        this.cameleonService.onError.subscribe((res) => {
            this.modalService.open('modal-fatal-error', { title: 'UNE ERREUR EST SURVENUE', text: 'Vous êtes resté trop longtemps inactif.<br/>Merci de fermer cette fenêtre et de recommencer une configuration.'});
        });

        var queryDict:any = {}
        location.search.substr(1).split("&").forEach(function(item) {queryDict[item.split("=")[0]] = item.split("=")[1]})

        this.applicationData.projectId = queryDict.chantier ? queryDict.chantier : '0';
        this.cameleonService.CC = queryDict.Cc ? queryDict.Cc : null;
        this.cameleonService.WKS = queryDict.Wks ? queryDict.Wks : 'WKS_Bouvet';
        this.applicationData.itemId = queryDict.itemId ? queryDict.itemId : null;
        this.applicationData.adherentCode = queryDict.Ca ? queryDict.Ca : null;
        this.applicationData.cooperativeCode = queryDict.Co ? queryDict.Co : null;
        
        this.applicationData.reference = queryDict.repere ? queryDict.repere : (window.repere ? window.repere : null);
        
        if (window.base64Data) {
            this.applicationData.base64Data = window.base64Data;
        }

        if (this.cameleonService.CC && this.applicationData.adherentCode && this.applicationData.cooperativeCode) {
            console.log('check');

            let httpOptions = {
                headers: new HttpHeaders({
                  'Authorization': 'Basic ' + btoa('EXT_ORCAB_REC' + ':' + 'HX3W34e4qWk9Zq86tyAb'),
                }),
                withCredentials: true
            };

            this.http.get('https://web09.bouvet-sa.fr/BC_REC_NUP/ODataV4/Company(\'BOUVET\')/CustomerAffiliates?$filter=AffiliateNo%20eq%20%27' + this.applicationData.adherentCode + '%27%20and%20GroupNo%20eq%20%27' + this.applicationData.cooperativeCode + '%27', httpOptions).subscribe((responseData) => {
				const res: any = responseData;
                
                if (res.value && res.value[0] && res.value[0].PriceCustomerNo == this.cameleonService.CC) {

                } else {
                    this.applicationState.state = ApplicationStates.ERROR;
                }
            }, (err) => {
                
            });

        }
    }

    /**
     * getState
     */
    getState(outlet) {
        return outlet.activatedRouteData.state;
    }

    @HostListener('window:unload', [ '$event' ])
    unloadHandler(event) {
        this.cameleonService.destroy();
    }
}
