/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "./textinput.component";
import * as i3 from "../../cameleon/cameleon.service";
var styles_TextInputComponent = [];
var RenderType_TextInputComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TextInputComponent, data: {} });
export { RenderType_TextInputComponent as RenderType_TextInputComponent };
export function View_TextInputComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "textinput__input"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "input", [["name", ""], ["type", "number"]], [[8, "id", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "blur"], [null, "keydown"], [null, "input"], [null, "compositionstart"], [null, "compositionend"], [null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 2)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 2)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("change" === en)) {
        var pd_4 = (i0.ɵnov(_v, 3).onChange($event.target.value) !== false);
        ad = (pd_4 && ad);
    } if (("input" === en)) {
        var pd_5 = (i0.ɵnov(_v, 3).onChange($event.target.value) !== false);
        ad = (pd_5 && ad);
    } if (("blur" === en)) {
        var pd_6 = (i0.ɵnov(_v, 3).onTouched() !== false);
        ad = (pd_6 && ad);
    } if (("ngModelChange" === en)) {
        var pd_7 = ((_co.value = $event) !== false);
        ad = (pd_7 && ad);
    } if (("blur" === en)) {
        var pd_8 = (_co.onBlur() !== false);
        ad = (pd_8 && ad);
    } if (("keydown" === en)) {
        var pd_9 = (_co.onKeydown($event) !== false);
        ad = (pd_9 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 16384, null, 0, i1.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i1.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵdid(3, 16384, null, 0, i1.ɵangular_packages_forms_forms_be, [i0.Renderer2, i0.ElementRef], null, null), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i1.DefaultValueAccessor, i1.ɵangular_packages_forms_forms_be]), i0.ɵdid(5, 671744, null, 0, i1.NgModel, [[8, null], [8, null], [8, null], [6, i1.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i1.NgControl, null, [i1.NgModel]), i0.ɵdid(7, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = ""; var currVal_9 = _co.value; _ck(_v, 5, 0, currVal_8, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fp.id; var currVal_1 = i0.ɵnov(_v, 7).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 7).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 7).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 7).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 7).ngClassValid; var currVal_6 = i0.ɵnov(_v, 7).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 7).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_TextInputComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "textinput", [["class", "textinput"]], null, null, null, View_TextInputComponent_0, RenderType_TextInputComponent)), i0.ɵdid(1, 245760, null, 0, i2.TextInputComponent, [i3.CameleonService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TextInputComponentNgFactory = i0.ɵccf("textinput", i2.TextInputComponent, View_TextInputComponent_Host_0, { fp: "fp" }, { onChange: "onChange" }, []);
export { TextInputComponentNgFactory as TextInputComponentNgFactory };
