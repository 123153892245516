import { Inject, Injectable, EventEmitter } from '@angular/core';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from '~/environments/environment';

import { SetConfigParameters } from './parameters/set-config.parameters';
import { RaiseEventParameters } from './parameters/raise-event.parameters';
import { GetCPStructureParameters } from './parameters/get-cp-structure.parameters';
import { GetFOStructureParameters } from './parameters/get-fo-structure.parameters';
import { GetFPStructureParameters } from './parameters/get-fp-structure.parameters';
import { GetBusinessValueParameters } from './parameters/get-business-value.parameters';
import { SaveConfigurationParameters } from './parameters/save-configuration.parameters';

import { FO } from './vo/FO.vo';
import { BV } from './vo/BV.vo';
import { WINPRO } from './vo/WINPRO.vo';
import { BrandTypes } from '~/environments/environment.types';

export enum CameleonStates {
  INIT = 0,
  LOADING = 1,
  READY = 2,
  ERROR = 3,
  FATALERROR = 4,
}

@Injectable()
export class CameleonService {

  public WKS = 'WKS_Bouvet';
  public CC:string;
  public _CP = '';
  set CP(value:string) {
    if (this._CP != value) {
      this.destroy();
      this._CP = value;
      this.state = CameleonStates.INIT;
    }
  }
  get CP():string {
    return this._CP;
  }

  public productDescription:string;

  public RESULT:WINPRO;

  public completed:boolean;
  public state:any = null;
  public logDirty:boolean = false;
  public messages:Array<any>;

  private _lastActivity:Date;
  private _pingInterval:number;
  private _httpOptions:any;
  private _sessionID:string;

  public breakdownTree:Array<FO>;
  public _foList:Array<string>;
  private _bvLibrary:Array<BV>;

  public onChange = new EventEmitter<any>();
  public onError = new EventEmitter<any>();

  public version = null;

  /**
	 * Constructor
	 */
  constructor(private http: HttpClient) {
    this.state = CameleonStates.INIT;

    if (environment.CAMELEON_USER) {
      this._httpOptions = {
        headers: new HttpHeaders({
          'Authorization': 'Basic ' + btoa(environment.CAMELEON_USER + ':' + environment.CAMELEON_PWD),
        }),
        withCredentials: true
      };
    } else {
      this._httpOptions = {
        headers: new HttpHeaders({

        }),
        withCredentials: true
      };
    }

    this._httpOptions.headers.append('Access-Control-Allow-Credentials', 'true');
    this._httpOptions.headers.append('Content-Type', 'application/json');
  }

  /**
	 * init
	 */
  init(data:any) {
    //mettre la récéption du message de l'extranet
    window.addEventListener('message',this.handleMessage,false);
    this.state = CameleonStates.LOADING;
    this._bvLibrary = [];
    this.breakdownTree = [];
    this.messages = [];

    return new Promise((resolve, reject) => {
      // Create engine
      this.loadParameters().then(
        (result: any) => {
          this.createEngine(result).then(
            (result: any) => {
              this.startPing();
              // Set config
              this.setConfig(data).then(
                (result: any) => {

                  // Update settings
                  this.updateSettings().then(
                    (result: any) => {

                      // Raise event
                      this.raiseEvent().then(
                        (result: any) => {

                          // Get CP Structure
                          this.getCPStructure().then(
                            (result: any) => {

                              // Get FO Structure
                              this.getFOStructure().then(
                                (result: any) => {

                                  // Get current State
                                  this.getCurrentState().then(
                                    (result: any) => {
                                      this.state = CameleonStates.READY;
                                      resolve(true);
                                    },
                                    (error: any) => {
                                      this.state = CameleonStates.FATALERROR;
                                      reject(error);
                                    },
                                  ).catch((e: Error) => { this.unknownError(reject, e); });

                                },
                                (error: any) => {
                                  this.state = CameleonStates.FATALERROR;
                                  reject(error);
                                },
                              ).catch((e: Error) => { this.unknownError(reject, e); });

                            },
                            (error: any) => {
                              this.state = CameleonStates.FATALERROR;
                              reject(error);
                            },
                          ).catch((e: Error) => { this.unknownError(reject, e); });

                        },
                        (error: any) => {
                          this.state = CameleonStates.FATALERROR;
                          reject(error);
                        },
                      ).catch((e: Error) => { this.unknownError(reject, e); });

                    },
                    (error: any) => {
                      this.state = CameleonStates.FATALERROR;
                      reject(error);
                    },
                  ).catch((e: Error) => { this.unknownError(reject, e); });
                },
                (error: any) => {
                  this.state = CameleonStates.FATALERROR;
                  reject(error);
                },
              ).catch((e: Error) => { this.unknownError(reject, e); });
            },
            (error: any) => {
              this.state = CameleonStates.FATALERROR;
              reject(error);
            },
          ).catch((e: Error) => { this.unknownError(reject, e); });
        },
        (error: any) => {
          this.state = CameleonStates.FATALERROR;
          reject(error);
        },
      ).catch((e: Error) => { this.unknownError(reject, e); });
  	});

  }

  /**
	 * Create engine
	 */
  loadParameters() {
    return new Promise((resolve, reject) => {
      let filename;
      if (this.version && this.version < 20) {
        filename = environment.BRAND == BrandTypes.MINCO ? 'create-engine.parameters.legacy.json' : 'create-engine.parameters.legacy.json';
      } else {
        filename = environment.BRAND == BrandTypes.MINCO ? 'create-engine.parameters.json' : 'create-engine.parameters.json';
      }
      
      this.http.get(environment.EXTRANET_SERVER + '/uploads/config/' + filename).subscribe((responseData) => {
				const res: any = responseData;
        resolve(res);
	    }, (err) => {
        this.unknownError(reject);
	    });
		});
  }

  /**
	 * Create engine
	 */
  createEngine(data) {
    return new Promise((resolve, reject) => {
      this.http.post(environment.CAMELEON_SERVER + environment.CAMELEON_API, data, this._httpOptions).subscribe((responseData) => {
				const res: any = responseData;

        if (res.CameleonResponse && res.CameleonResponse.exception) {
          reject(res.CameleonResponse.exception);
        } else {
          this._sessionID = res.CameleonResponse.long;
          resolve(res);
        }
	    }, (err) => {
        this.unknownError(reject);
	    });
		});
  }

  /**
	 * Set config
	 */
  setConfig(data:any) {
    if (data) {
      data = {
        "parameters": {
          "parameter": [{
            "@name": "savedConfiguration",
            "string": data
          }, {
            "@name": "overrideSessionSettings",
            "boolean": true
          }, {
            "@name": "withException",
            "boolean": true
          }, {
            "@name": "create",
            "boolean": false
          }]
        }
      }
    } else {
      data = JSON.parse(JSON.stringify(SetConfigParameters.defaults));
      data.parameters[0].ConfigurationSettings.Session.Param.find((row:any)=>{
          return (row["@cpe"] == "CPE.Settings.Session.Workspace");
      })["@value"] = this.WKS;
      data.parameters[0].ConfigurationSettings.Session.Param.push({
        '@cpe': 'CPE.Settings.Session.CPName',
        '@value': this.CP
      });
    }

    return new Promise((resolve, reject) => {
			this.http.put(environment.CAMELEON_SERVER + environment.CAMELEON_API+'/'+this._sessionID, data, this._httpOptions).subscribe((responseData) => {
				const res: any = responseData;

        if (res.CameleonResponse && res.CameleonResponse.exception) {
          reject(res.CameleonResponse.exception);
        } else {
          let CPName:any;
          if (res.CameleonResponse.ResultWithFailures) {
            CPName = res.CameleonResponse.ResultWithFailures.Result.ConfiguratorSession.Settings.Session.filter((data) => {
              return data['@cpe'] == 'CPE.Settings.Session.CPName';
            });
          } else {
            CPName = res.CameleonResponse.ConfiguratorSession.Settings.Session.filter((data) => {
              return data['@cpe'] == 'CPE.Settings.Session.CPName';
            });
          }

          if (CPName.length > 0) {
            this._CP = CPName[0]['@value'];
          }

          resolve(res);
        }
	    }, (err) => {
        if (err.error.CameleonResponse.exception.message.$.indexOf('The handler of configurators type for '+this._sessionID) == 0) {
          this.state = CameleonStates.FATALERROR;
          reject({
            "type": "CookieException",
            "message": {
              "@criticity": "FATALERROR",
              "$": "Votre navigateur semble bloquer les cookies tiers et ne vous permet pas d’accéder à notre configurateur produit.<br> Vous devez donc modifier son paramétrage.<br>\
              Par exemple sur Safari : «Préférences > Confidentialité > Empêcher le suivi sur plusieurs domaines»"
            }
          });
        } else {
          this.unknownError(reject);
        }
	    });
		});
  }

  /**
	 * Raise event
	 */
  raiseEvent() {
    let data = JSON.parse(JSON.stringify(RaiseEventParameters.defaults));
    data.parameters[0].clientevent['$'] = 'CPE.'+this.WKS+'/CP/' + this.CP;
    return new Promise((resolve, reject) => {
			this.http.post(environment.CAMELEON_SERVER + environment.CAMELEON_API+'/'+this._sessionID+'/raiseEvent', data, this._httpOptions).subscribe((responseData) => {
				const res: any = responseData;

        if (res && res.CameleonResponse && res.CameleonResponse.exception) {
          reject(res.CameleonResponse.exception);
        } else {
          resolve(res);
        }
	    }, (err) => {
        this.unknownError(reject);
	    });
		});
  }

  /**
	 * Update settings
	 */
  updateSettings() {

    return new Promise((resolve, reject) => {
      let filename;
      if (this.version && this.version < 20) {
        filename = environment.BRAND == BrandTypes.MINCO ? 'update-settings.parameters.minco.legacy.json' : 'update-settings.parameters.json';
      } else {
        filename = environment.BRAND == BrandTypes.MINCO ? 'update-settings.parameters.minco.json' : 'update-settings.parameters.json';
      }

      this.http.get(environment.EXTRANET_SERVER + '/uploads/config/' + filename).subscribe((responseData) => {
        let data;
        data = responseData;

        if (this.CC) {
          data.parameters[0].list.push(
            {
                "pair": {
                    "left": {
                        "cpe": "CPE.Settings.Session.Application.ParameterTab.CustomerId"
                    },
                    "right": {
                        "string": this.CC
                    }
                }
            }
          );
        }

        this.http.patch(environment.CAMELEON_SERVER + environment.CAMELEON_API+'/'+this._sessionID+'/settings', data, this._httpOptions).subscribe((responseData) => {
          const res: any = responseData;

          if ( res && res.CameleonResponse && res.CameleonResponse.exception) {
            reject(res.CameleonResponse.exception);
          } else {
            resolve(res);
          }
        }, (err) => {
          this.unknownError(reject);
        });
      }, (err) => {
        this.unknownError(reject);
      });
		});
  }

  /**
	 * Get FO structure
	 */
  getFOStructure() {
    let data = JSON.parse(JSON.stringify(GetFOStructureParameters.defaults));
    for (let fo of this._foList) {
      data.parameters[0].list.push({'cpe': fo});
    }
    return new Promise((resolve, reject) => {
			this.http.post(environment.CAMELEON_SERVER + environment.CAMELEON_API+'/'+this._sessionID+'/forms/search', data, this._httpOptions).subscribe((responseData) => {
				const res: any = responseData;

        if (res && res.CameleonResponse && res.CameleonResponse.exception) {
          reject(res.CameleonResponse.exception);
        } else {
          let tempBreakdownTree = [];
          let ready = true;

          for (let fo of res.CameleonResponse.list) {
            let lfo = this.breakdownTree.filter(fol => (fol.id === fo['Form']['@cpe']));
            let cfo;
            if (lfo.length > 0) {
              cfo = lfo[0];
              lfo[0].update(fo['Form']);
            } else {
              if (fo.Form.Status['@exist'] == "true" && fo.Form['@name'] != 'FO_WinproAnswer' && fo.Form['@name'] != 'FO_DataBase') {
                cfo = new FO(fo.Form);
              }
            }

            if (cfo) {
              tempBreakdownTree.push(cfo);
              if (!cfo.completed) {
                ready = false;
              }
            }
          }

          this.breakdownTree = tempBreakdownTree;
          this.completed = ready;

          resolve(res);
        }
	    }, (err) => {
        this.unknownError(reject);
	    });
		});
  }

  /**
	 * Get CP structure
	 */
  getCPStructure() {
    let data = JSON.parse(JSON.stringify(GetCPStructureParameters.defaults));
    data.parameters[0].list.push({'cpe': 'CPE.'+this.WKS+'/CP/' + this.CP});

    return new Promise((resolve, reject) => {
			this.http.post(environment.CAMELEON_SERVER + environment.CAMELEON_API+'/'+this._sessionID+'/configurableProducts/search', data, this._httpOptions).subscribe((responseData) => {
				const res: any = responseData;

        if (res && res.CameleonResponse && res.CameleonResponse.exception) {
          reject(res.CameleonResponse.exception);
        } else {
          this._foList = [];
          for (let fo of res.CameleonResponse.list[0].ConfigurableProduct.Children) {
            this._foList.push(fo.FormChild['@cpe']);
          }

          this.productDescription = res.CameleonResponse.list[0].ConfigurableProduct.Description;

          resolve(res);
        }
	    }, (err) => {
        this.unknownError(reject);
	    });
		});

  }

  /**
	 * Get current configuration state from Caméléon
	 */
  getCurrentState() {
    let data = JSON.parse(JSON.stringify(GetFPStructureParameters.defaults));

    for (let fo of this.breakdownTree) {
      for (let fp of fo.fps) {
        data.parameters[0].list.push({cpe: fp.id});
      }
    }

    return new Promise((resolve, reject) => {
			this.http.post(environment.CAMELEON_SERVER + environment.CAMELEON_API+'/'+this._sessionID+'/formProperties/search', data, this._httpOptions).subscribe((responseData) => {
				const res: any = responseData;

        if (res && res.CameleonResponse && res.CameleonResponse.exception) {
          reject(res.CameleonResponse.exception);
        } else {
          for (let fo of this.breakdownTree) {
            for (let fp of fo.fps) {
              let lfp = res.CameleonResponse.list.filter(fpl => (fpl.FormProperty['@cpe'] === fp.id));
              if (lfp.length > 0) {
                fp.update(lfp[0]['FormProperty']);
              }
            }
          }

          // Get current State
          this.getMissingBV().then(
            (result: any) => {
              this.state = CameleonStates.READY;
              resolve(res);
            },
            (error: any) => {
              this.state = CameleonStates.FATALERROR;
              reject(error);
            },
          ).catch((e: Error) => { this.unknownError(reject, e); });

        }
	    }, (err) => {
        this.unknownError(reject);
	    });
		});
  }

  /**
	 * Get missing business values from Caméléon
	 */
  getMissingBV() {
    let data = JSON.parse(JSON.stringify(GetBusinessValueParameters.defaults));
    //00console.log('test', data);

    for (let fo of this.breakdownTree) {
      for (let fp of fo.fps) {
        for (let bv of fp.bvs) {
          if (bv.type == 'object') {
            //console.log('bv ',bv);
            let c = this._bvLibrary.filter(bvl => (bvl.cpe == bv.cpe));
            //console.log('c', data.parameters[0]);
            if (c.length == 0) {
              data.parameters[0].list.push({cpe: bv.cpe});
            }
          }
        }
      }
    }

    return new Promise((resolve, reject) => {
      if (data.parameters[0].list.length > 0) {
  			this.http.post(environment.CAMELEON_SERVER + environment.CAMELEON_API+'/'+this._sessionID+'/businessValues/search', data, this._httpOptions).subscribe((responseData) => {
  				const res: any = responseData;

          if (res && res.CameleonResponse && res.CameleonResponse.exception) {
            reject(res.CameleonResponse.exception);
          } else {
            for (let bv of res.CameleonResponse.list) {
              this._bvLibrary.push(new BV(bv.BusinessValue));
            }

            resolve(res);
          }
  	    }, (err) => {
          this.unknownError(reject);
  	    });
      } else {
        resolve(true);
      }
    });

  }

  /**
	 * Get FO breakdown
	 */
  getFO(id:string):FO {
//    console.log(this.breakdownTree);
    return this.breakdownTree.filter(fo => (fo.id === 'CPE.'+this.WKS+'/CP/'+this.CP+'.'+this.WKS+'/FO/' + id))[0];
  }

  /**
	 * Get BV description
	 */
  getBVDescription(bv:BV):string {

    if (bv.description) {
      return bv.description;
    }

    let desc = this._bvLibrary.filter(bvl => (bvl.id === bv.id));

    return desc.length > 0 ? desc[0].description : bv.description;
  }

  /**
	 * Get BV infos
	 */
  getBVInfos(bv:BV):string {

    let desc = this._bvLibrary.filter(bvl => (bvl.id === bv.id));

    return desc.length > 0 ? desc[0].infos : null;
  }

  /**
	 * Get BV image
	 */
  getBVImage(id:string):string {
    let img = this._bvLibrary.filter(bv => (bv.id === id));

    return img.length > 0 ? img[0].image : null;
  }

  /**
	 * Set Value
	 */
  setValue(data:any) {

    return new Promise((resolve, reject) => {
			this.http.post(environment.CAMELEON_SERVER + environment.CAMELEON_API+'/'+this._sessionID+'/valuations', data, this._httpOptions).subscribe((responseData) => {
				const res: any = responseData;

        if (res && res.CameleonResponse && res.CameleonResponse.exception) {
          reject(res.CameleonResponse.exception);
        } else {
          if (res.CameleonResponse.Failures.length > 0) {
            let errorMessage = "";
            for (let err of res.CameleonResponse.Failures) {
              errorMessage += (err.SetValuationFailure.Message.TranslatedMessage)+'<br/>';
            }
            reject({
              "type": "CameleonException",
              "message": {
                "@criticity": "ERROR",
                "$": errorMessage
              }
            });
          } else {

            this.logDirty = true;

            // Get FO Structure
            this.getFOStructure().then(
              (result: any) => {
                // Get current State
                this.getCurrentState().then(
                  (result: any) => {
                    resolve(true);
                  },
                  (error: any) => {
                    this.state = CameleonStates.FATALERROR;
                    reject(error);
                  },
                ).catch((e: Error) => { this.unknownError(reject, e); });
              },
              (error: any) => {
                this.state = CameleonStates.FATALERROR;
                reject(error);
              },
            ).catch((e: Error) => { this.unknownError(reject, e); });
          }
        }
	    }, (err) => {
        this.unknownError(reject);
	    });
		});
  }

  /**
	 * Set Value
	 */
  getUserMessage(data:any) {
    return new Promise((resolve, reject) => {
      this.http.get(environment.CAMELEON_SERVER + environment.CAMELEON_API+'/'+this._sessionID+'/objects/'+data.parameters[0].list[0].pair.left.cpe+'/userMessages', this._httpOptions).subscribe((responseData) => {
				const res: any = responseData;

        if (res && res.CameleonResponse && res.CameleonResponse.exception) {
          reject(res.CameleonResponse.exception);
        } else {
          if (res && res.CameleonResponse && res.CameleonResponse.list) {
            if (res.CameleonResponse.list.length > 0) {
              this.messages[data.parameters[0].list[0].pair.left.cpe] = [];
              for (let message of res.CameleonResponse.list) {
                this.messages[data.parameters[0].list[0].pair.left.cpe].push({
                  read: false,
                  message: message.UserMessage.TranslatedMessage
                });
              }
            } else {
              delete this.messages[data.parameters[0].list[0].pair.left.cpe];
            }
          }

          resolve(this.messages);

        }
	    }, (err) => {
        this.unknownError(reject);
	    });
		});
  }

  /**
	 * Get result
	 */
  getResult() {
    return new Promise((resolve, reject) => {
      // Trigger Winpro
      this.RESULT = new WINPRO();
 			this.triggerWinpro().then(
        (result: any) => {
          if (result) {
            // Get WinPRO message
            this.getWinProMessage().then(
              (result: any) => {
                // Get product price
           			this.getWinProData('FP_PrixMenuiserie').then(
                  (result: any) => {
                    this.RESULT.productPrice = result.convertedNumericValue['@refValue'];

                    // Get product discount
               			this.getWinProData('FP_RemisePrixMenuiserie').then(
                      (result: any) => {
                        this.RESULT.productDiscount = result.integerValue;

                        // Get RS price
                   			this.getWinProData('FP_PrixVolet').then(
                          (result: any) => {
                            this.RESULT.rsPrice = result.convertedNumericValue['@refValue'];

                            // Get RS discount
                       			this.getWinProData('FP_RemisePrixVolet').then(
                              (result: any) => {
                                this.RESULT.rsDiscount = result.integerValue;

                                // Get Drawing
                           			this.getWinProData('FP_DrawingPath1').then(
                                  (result: any) => {
                                    this.RESULT.drawingPath = result.longTextValue;
                                    // Get Drawing
                               			this.getWinProData('FP_QuoteId').then(
                                      (result: any) => {
                                        this.RESULT.quoteId = result.textValue.replace('.jpg', '');
                                        // Get Generative Process
                                   			this.getGenerativeProcess().then(
                                          (result: any) => {
                                            this.RESULT.generativeProcess = result;
                                            resolve(this.RESULT);
                                          },
                                          (error: any) => {
                                            this.state = CameleonStates.FATALERROR;
                                            reject(error);
                                          },
                                        ).catch((e: Error) => { this.unknownError(reject, e); });
                                      },
                                      (error: any) => {
                                        this.state = CameleonStates.FATALERROR;
                                        reject(error);
                                      },
                                    ).catch((e: Error) => { this.unknownError(reject, e); });
                                  },
                                  (error: any) => {
                                    this.state = CameleonStates.FATALERROR;
                                    reject(error);
                                  },
                                ).catch((e: Error) => { this.unknownError(reject, e); });

                              },
                              (error: any) => {
                                this.state = CameleonStates.FATALERROR;
                                reject(error);
                              },
                            ).catch((e: Error) => { this.unknownError(reject, e); });

                          },
                          (error: any) => {
                            this.state = CameleonStates.FATALERROR;
                            reject(error);
                          },
                        ).catch((e: Error) => { this.unknownError(reject, e); });

                      },
                      (error: any) => {
                        this.state = CameleonStates.FATALERROR;
                        reject(error);
                      },
                    ).catch((e: Error) => { this.unknownError(reject, e); });

                  },
                  (error: any) => {
                    this.state = CameleonStates.FATALERROR;
                    reject(error);
                  },
                ).catch((e: Error) => { this.unknownError(reject, e); });
              },
              (error: any) => {
                //this.state = CameleonStates.FATALERROR;
                reject(error);
              },
            ).catch((e: Error) => { this.unknownError(reject, e); });

          } else {
            this.state = CameleonStates.ERROR;
            this.unknownError(reject);
          }
        },
        (error: any) => {
          this.state = CameleonStates.FATALERROR;
          reject(error);
        },
      ).catch((e: Error) => { this.unknownError(reject, e); });
 		});
  }

  /**
   * Trigger WINPRO
   */
  triggerWinpro() {
    return new Promise((resolve, reject) => {
      this.http.get(environment.CAMELEON_SERVER + environment.CAMELEON_API+'/'+this._sessionID+'/formProperties/CPE.'+this.WKS+'/CP/'+this.CP+'.'+this.WKS+'/FO/FO_WinproAnswer.FP/FP_Declencheur', this._httpOptions).subscribe((responseData) => {
        const res: any = responseData;

        if (res.CameleonResponse && res.CameleonResponse.exception) {
          reject(res.CameleonResponse.exception);
        } else {
          if (res
            && res.CameleonResponse
            && res.CameleonResponse.FormProperty
            && res.CameleonResponse.FormProperty.SingleValuation.Value
          ) {
            resolve(res.CameleonResponse.FormProperty.SingleValuation.Value.booleanValue == "true");
          } else {
            this.unknownError(reject);
          }

        }
      }, (err) => {
        this.unknownError(reject);
      });
    });
  }

  /**
   * Get Winpro message
   */
  getWinProMessage() {

    return new Promise((resolve, reject) => {
      this.http.get(environment.CAMELEON_SERVER + environment.CAMELEON_API+'/'+this._sessionID+'/formProperties/CPE.'+this.WKS+'/CP/'+this.CP+'.'+this.WKS+'/FO/FO_WinproAnswer.FP/FP_ErrorMessage', this._httpOptions).subscribe((responseData) => {
        const res: any = responseData;

        if (res
          && res.CameleonResponse
          && res.CameleonResponse.FormProperty
          && res.CameleonResponse.FormProperty.SingleValuation.Value
        ) {
          if (res.CameleonResponse.FormProperty.SingleValuation.Value.longTextValue == 'NONE') {
            resolve(true);
          } else {
            reject({
              message: {
                $: res.CameleonResponse.FormProperty.SingleValuation.Value.longTextValue
              }
            });
          }
        }
      }, (err) => {
        this.unknownError(reject);
      });
    });
  }

  /**
   * Get Winpro data
   */
  getWinProData(cpe:String) {

    return new Promise((resolve, reject) => {
      this.http.get(environment.CAMELEON_SERVER + environment.CAMELEON_API+'/'+this._sessionID+'/formProperties/CPE.'+this.WKS+'/CP/'+this.CP+'.'+this.WKS+'/FO/FO_WinproAnswer.FP/'+cpe, this._httpOptions).subscribe((responseData) => {
        const res: any = responseData;

        if (res.CameleonResponse && res.CameleonResponse.exception) {
          reject(res.CameleonResponse.exception);
        } else {
          resolve(res.CameleonResponse.FormProperty.SingleValuation.Value);
        }
      }, (err) => {
        this.unknownError(reject);
      });
    });
  }

  /**
   * Get Generative Process
   */
  getGenerativeProcess() {

    return new Promise((resolve, reject) => {
      this.http.get(environment.CAMELEON_SERVER + environment.CAMELEON_API+'/'+this._sessionID+'/generativeProcessesResult', this._httpOptions).subscribe((responseData) => {
        const res: any = responseData;
        console.log(responseData);
        if (res.CameleonResponse && res.CameleonResponse.exception) {
          reject(res.CameleonResponse.exception);
        } else {
          let salesBreakdown = [];

          for (let entry of res.CameleonResponse.GenerativeProcessesResult.SalesBreakdownTree[0].Sig[0].BusinessPropertySets[0].BusinessProperties) {
            if (entry.SingleBusinessProperty.Value && entry.SingleBusinessProperty.Value.textValue != '#NA#') {
              let label = entry.SingleBusinessProperty["@name"].replace(/^IFQ_/, '');
              label = label.replace(/_/g, ' ');
              salesBreakdown.push({
                label: label,
                value: entry.SingleBusinessProperty.Value.textValue,
                mandatory: (entry.SingleBusinessProperty["@name"].match(/^IFQ_/) == null) // Ignore For Quotation
              });
            }
          }

          resolve(salesBreakdown);
        }
      }, (err) => {
        this.unknownError(reject);
      });
    });
  }

  /**
   * Save configuration
   */
  save() {
    let data = JSON.parse(JSON.stringify(SaveConfigurationParameters.defaults));

    return new Promise((resolve, reject) => {
      this.http.post(environment.CAMELEON_SERVER + environment.CAMELEON_API+'/'+this._sessionID+'/saveConfiguration', data, this._httpOptions).subscribe((responseData) => {
        const res: any = responseData;

        if (res.CameleonResponse && res.CameleonResponse.exception) {
          reject(res.CameleonResponse.exception);
        } else {
          resolve(res.CameleonResponse.string);
        }
      }, (err) => {
        this.unknownError(reject);
      });
    });
  }

  /**
   * Send Config
   */
  sendToExtranet(data) {
    let httpOptions = {
      headers: new HttpHeaders({
      }),
      withCredentials: true
    };

    return new Promise((resolve, reject) => {
      this.http.post(environment.EXTRANET_SERVER + environment.EXTRANET_API+'/quote-item', data, httpOptions).subscribe((responseData) => {
        const res: any = responseData;
        resolve(true);
      }, (err) => {
        this.unknownError(reject);
      });
    });
  }

  /**
   * Get Config
   */
  getDataFromExtranet(itemId) {
    let httpOptions = {
      headers: new HttpHeaders({
      })
    };

    return new Promise((resolve, reject) => {
      this.http.get(environment.EXTRANET_SERVER + environment.EXTRANET_API+'/quote-item-configuration/'+itemId, httpOptions).subscribe((responseData) => {
        const res: any = responseData;
        resolve(res);
      }, (err) => {
        this.unknownError(reject);
      });
    });
  }

  /**
	 * Reject with Unknown Error
	 */
  unknownError(reject, e: Error = null) {
    this.state = CameleonStates.FATALERROR;
    reject({
      "type": "RuntimeException",
      "message": {
        "@criticity": "FATALERROR",
        "$": "Une erreur inconnue est survenue. Merci de fermer cette fenêtre et de recommencer une configuration."
      }
    });
  }

  /**
   * Delete engine
   */
  deleteEngine() {
    var client = new XMLHttpRequest();
    client.open("DELETE", environment.CAMELEON_SERVER + environment.CAMELEON_API+'/'+this._sessionID, true); // third parameter indicates synchronous ajax
    if (environment.CAMELEON_USER) {
      client.setRequestHeader("Authorization", "Basic " + btoa(environment.CAMELEON_USER+":"+environment.CAMELEON_PWD));
    }
    client.setRequestHeader('Content-Type', 'application/json');
    client.withCredentials = true;
    client.send();
  }
  handleMessage = (e:any) => {
    if(e.data === "USER_CLOSE_IFRAME")
    {
      this.deleteEngine();
        clearInterval(this._pingInterval);
      //postMessage
        let data = {
            from: 'configurator',
            finish: true
        }
        parent.postMessage(JSON.stringify(data),"*");
    }
  }
  /**
	 * Start Ping
	 */
  startPing() {
    this._lastActivity = new Date();

    this._pingInterval = setInterval(() => {
      if ((new Date().getTime() - this._lastActivity.getTime()) / 1000 > environment.KEEP_ALIVE) {
        this.onError.emit('timeout');
        this.state = CameleonStates.FATALERROR;
        clearInterval(this._pingInterval);
      } else {
        this.http.put(environment.CAMELEON_SERVER + environment.CAMELEON_API+'/'+this._sessionID+'/ping', null, this._httpOptions).subscribe((responseData) => {
  				const res: any = responseData;
          if (res && res.CameleonResponse && res.CameleonResponse.exception) {
            this.state = CameleonStates.FATALERROR;
            this.onError.emit('timeout');
            clearInterval(this._pingInterval);
          } else {

          }
  	    }, (err) => {
          this.state = CameleonStates.FATALERROR;
          this.onError.emit('timeout');
          clearInterval(this._pingInterval);
  	    });
      }
    }, 60 * 1000);

  }

  /**
   * Log error
   */
  log(data) {
    let httpOptions = {
      headers: new HttpHeaders({})
    };

    if (this.logDirty) {
      if (data.datas === null) {
        this.save()
          .then(
            (result: any) => {
              data.datas = result;
              this.http.post(environment.EXTRANET_SERVER + environment.EXTRANET_API+'/log-configuration', data, httpOptions).subscribe((responseData) => {
                this.logDirty = false;
              });
            }, (err) => {
              console.log(err);
            });
      } else {
        this.http.post(environment.EXTRANET_SERVER + environment.EXTRANET_API+'/log-configuration', data, httpOptions).subscribe((responseData) => {
          this.logDirty = false;
        });
      }
    }
  }

  /**
	 * Keep alive
	 */
  keepAlive() {
    this._lastActivity = new Date();
  }

  /**
	 * Destroy
	 */
  destroy() {
    if (this._sessionID) {
      this.deleteEngine();
      this._sessionID = null;
      this.breakdownTree = [];
      this.messages = [];
      this.completed = false;
      clearInterval(this._pingInterval);
    }
    this.state = CameleonStates.INIT;
  }

}
