export const SetConfigParameters = {
  defaults: {
   "parameters": [
    {
     "@name": "configurationSettings",
     "ConfigurationSettings": {
      "Session": {
       "Param": [
        {
         "@cpe": "CPE.Settings.Session.Workspace",
         "@value": "WKS_Bouvet"
        },
        {
         "@cpe": "CPE.Settings.Session.Qty",
         "@value": "1"
        },
        {
         "@cpe": "CPE.Settings.Session.Currency",
         "@value": "EUR"
        },
        {
         "@cpe": "CPE.Settings.Session.CurrencySymbol",
         "@value": "€"
        },
        {
         "@cpe": "CPE.Settings.Session.AllowIncompleteSave",
         "@value": "true"
        },
        {
         "@cpe": "CPE.Settings.Session.SalesMethodName",
         "@value": "BouvetMinco_SM"
         //"@value": "MINCO_2019_SM"
        },
        {
         "@cpe": "CPE.Settings.Session.PricingMethod[1]",
         "@value": "WKS_Commun/PRGM/BouvetMinco_PM"
         //"@value": "WKS_Commun/PRGM/pm_Minco_2019"
        }
       ]
      }
     }
    },
    {
     "@name": "create",
     "boolean": "true"
    }
   ]
  }
}
