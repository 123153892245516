import { EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { CameleonService } from '~/app/shared/cameleon/cameleon.service';
var ImageSelectGroupComponent = /** @class */ (function () {
    /**
       * Constructor
       */
    function ImageSelectGroupComponent(cameleonService) {
        this.cameleonService = cameleonService;
        this._json = {
            "parameters": [
                {
                    "@name": "valuations",
                    "list": [
                        {
                            "pair": {
                                "left": {
                                    "cpe": ""
                                },
                                "right": {
                                    "SingleValuation": {
                                        "@isCommentUserChoice": "false",
                                        "@isQtyUserChoice": "false",
                                        "@isValueUserChoice": "true",
                                        "Value": {
                                            "@userQty": null,
                                            "pkValue": ""
                                        },
                                        "Comment": null
                                    }
                                }
                            }
                        }
                    ]
                }
            ]
        };
        this._value = '';
        this.onChange = new EventEmitter();
    }
    Object.defineProperty(ImageSelectGroupComponent.prototype, "fp", {
        get: function () { return this._fp; },
        set: function (data) {
            this._fp = data;
            this._json.parameters[0].list[0].pair.left.cpe = this.fp.id;
            this._value = this.fp.value;
            this._json.parameters[0].list[0].pair.right.SingleValuation.Value.pkValue = this._value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ImageSelectGroupComponent.prototype, "value", {
        get: function () { return this._value; },
        set: function (data) {
            this._value = data;
            this._json.parameters[0].list[0].pair.right.SingleValuation.Value.pkValue = data;
            this.onChange.emit(this._json);
        },
        enumerable: true,
        configurable: true
    });
    /**
     * On init
     */
    ImageSelectGroupComponent.prototype.ngOnInit = function () {
    };
    /**
    * On destroy
    */
    ImageSelectGroupComponent.prototype.ngOnDestroy = function () {
    };
    return ImageSelectGroupComponent;
}());
export { ImageSelectGroupComponent };
