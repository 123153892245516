import { FP } from './FP.vo';
var FO = /** @class */ (function () {
    function FO(data) {
        if (data === void 0) { data = null; }
        if (data) {
            this._id = data['@cpe'];
            this._name = data['@name'];
            this._description = data['Description'];
            this._fps = [];
            for (var _i = 0, _a = data['Children']; _i < _a.length; _i++) {
                var fp = _a[_i];
                this._fps.push(new FP(fp['FormPropertyChild']));
            }
            this.update(data);
        }
    }
    FO.prototype.update = function (data) {
        this._rawData = data;
    };
    Object.defineProperty(FO.prototype, "id", {
        get: function () {
            return this._id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FO.prototype, "name", {
        get: function () {
            return this._name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FO.prototype, "description", {
        get: function () {
            return this._description;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FO.prototype, "fps", {
        get: function () {
            return this._fps;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FO.prototype, "visible", {
        get: function () {
            return this._rawData.Status['@visible'] == "true" && this._rawData.Status['@exist'] == "true";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FO.prototype, "completed", {
        get: function () {
            return this._rawData.Status['@completed'] == "true";
        },
        enumerable: true,
        configurable: true
    });
    return FO;
}());
export { FO };
