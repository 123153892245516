export const RaiseEventParameters = {
  defaults: {
    "parameters": [
      {
        "@name": "event",
        "clientevent": {
          "@type": "ON_ACTIVATE",
          "$": ""
        }
      }
    ]
  }
}
