<div class="image-select-group__item" *ngFor="let bv of fp.bvs">
  <div class="image-select">
    <label class="image-select__label" [for]="fp.id+'/'+bv.id"  onclick="">
      <input type="radio" [name]="fp.id" [id]="fp.id+'/'+bv.id" [(ngModel)]="value" [value]="bv.id">
      <div class="image-select__container">
        <img *ngIf="cameleonService.getBVImage(bv.id)" [src]="cameleonService.getBVImage(bv.id)" alt="" class="image-select__image">
        <div class="image-select__description">
          {{ cameleonService.getBVDescription(bv) }}
        </div>

        <span *ngIf="cameleonService.getBVInfos(bv)" class="question__notice">
          <div [innerHTML]="cameleonService.getBVInfos(bv)"></div>
        </span>
      </div>
    </label>
  </div>

  <!-- <span *ngIf="cameleonService.getBVInfos(bv)" class="question__notice">
    <div [innerHTML]="cameleonService.getBVInfos(bv)"></div>
  </span> -->
</div>
